import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
// import { convertToHTML } from "draft-convert";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichTextEditor.css";

import { useStoreState, useStoreActions } from "easy-peasy";

export default function RichTextEditor() {
  // const formElementsData = useStoreState((state) => state.formElementsData);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const currentEl = formElementsPDF.filter((item) => {
    return item.isEdit === true;
  });

  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [convertedContent, setConvertedContent] = useState(null);
  const editorDisplay = useStoreState((state) => state.editorDisplay);

  useEffect(() => {
    let currentEl = formElementsPDF.filter((item) => {
      console.log(item);
      return item.isEdit === true;
    });
    console.log(currentEl);
    if (currentEl && currentEl[0]) {
      setEditorState(() => {
        if (editorDisplay) {
          const contentBlock = htmlToDraft(currentEl[0].formElementData.content);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createEmpty();
        }
      });
    }
  }, [editorDisplay]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(currentContentAsHTML);
    updFormElementsPDF(
      formElementsPDF.map(function (item, i) {
        if (item.isEdit === true) {
          // console.log(item);
          let currentFormElement = {
            ...item.formElementData,
            content: currentContentAsHTML,
          };
          return { ...item, formElementData: currentFormElement };
        } else {
          // console.log(item);

          return item;
        }
      })
    );
  };

  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // };

  return (
    <>
      <Editor
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "remove",
            "history",
          ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"],
          },
        }}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
      {/* <div
        className="preview2"
        dangerouslySetInnerHTML={createMarkup(convertedContent)}
      ></div> */}
      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </>
  );
}
