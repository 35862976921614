import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Accordion } from "react-bootstrap";
// import Sign from "../signature/Sign";
// import * as dayjs from "dayjs";
import SignPopup from "../signature/SignPopup";
import { AsYouType, validatePhoneNumberLength} from 'libphonenumber-js'
import jwt_decode from "jwt-decode";

const FormElements = {};

const PatientName = (props) => {
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });
  return (
    <Form.Group controlId="formgr_patient_name">
      <Form.Label className={`${required} ${formBuilder}`} dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        required={required}
        className={required}
        type="input"
        // className={props.elData.required ? "required" : ""}
        placeholder="Enter name"
        defaultValue={props.formBuilder ? "" : `${props.formEl.inputValue}`}
        name={`${props.elData.name}_${props.elData.id}`}
        onChange={(event) => props.handleChange(event, props)}
        tabIndex={props.tabIndex}
      />
    </Form.Group>
  );
};
const PatientEmail = (props) => {
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });
  return (
    <Form.Group controlId="formgr_patient_email">
      <Form.Label className={`${required} ${formBuilder}`} dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        required={required}
        className={required}
        type="email"
        // className={props.elData.required ? "required" : ""}
        placeholder="Enter email"
        defaultValue={props.formBuilder ? "" : `${props.formEl.inputValue}`}
        name={`${props.elData.name}_${props.elData.id}`}
        onChange={(event) => props.handleChange(event, props)}
        tabIndex={props.tabIndex}
      />
    </Form.Group>
  );
};

const PatientPhone = (props) => {
  const phoneInputFormatValue = (value) => {
    const country = props.formUser.country
    let rawValue = value
    // check when amount of numbers surpass the limit and not let more input
    if (validatePhoneNumberLength(rawValue, country) === 'TOO_LONG') {
        rawValue = rawValue.slice(0,-1);
    }
    // format value using users country
    const formattedValue = new AsYouType(country).input(rawValue)
    return formattedValue
  }
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });
  return (
    <Form.Group controlId="formgr_patient_phone">
      <Form.Label className={`${required} ${formBuilder}`} dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        required={required}
        className={required}
        type="tel"
        defaultValue={props.formBuilder ? "XXXXXXXXXX" : `${props.formEl.inputValue}`}
        name={`${props.elData.name}_${props.elData.id}`}
        onChange={(event) => {event.target.value = phoneInputFormatValue(event.target.value); props.handleChange(event, props)}}
        tabIndex={props.tabIndex}
      />
    </Form.Group>
  );
};

const Title = (props) => {
  // console.log({ props });
  return (
    <div>
      <h3 dangerouslySetInnerHTML={{ __html: props.elData.content }}></h3>
    </div>
  );
};

const StaticText = (props) => {
  return (
    <div>
      <p dangerouslySetInnerHTML={{ __html: props.elData.content }}></p>
    </div>
  );
};

const InputCustom = (props) => {
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });

  return (
    <Form.Group controlId={`form_input_${props.elData.id}`}>
      <Form.Label className={`${required} ${formBuilder}`} dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        required={required}
        className={required}
        type={props.elData.isNumeric ? "number" : "input"}
        name={`${props.elData.name}_${props.elData.id}`}
        onChange={(event) => props.handleChange(event, props)}
        placeholder={props.formBuilder ? "..." : ``}
        defaultValue={props.formBuilder ? "" : `${props.formEl.inputValue}`}
        inputMode={props.elData.isNumeric ? "numeric" : "text"}
        tabIndex={props.tabIndex}
      />
      <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
    </Form.Group>
  );
};

const SelectCustom = (props) => {
  const options = props.elData.options;
  const listItems = options.map((item, i) => (
    <option key={i.toString()} value={item}>
      {item}
    </option>
  ));
  // set initial value to props.handleChange

  return (
    <Form.Group controlId="formGrid">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        as="select"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
        defaultValue="Choose..."
      >
        {listItems}
        {/* <option>Choose...</option>
          <option>...</option> */}
      </Form.Control>
    </Form.Group>
  );
};

const TextareaCustom = (props) => {
  return (
    <Form.Group controlId="formBasicEmail">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        as="textarea"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
        rows={1}
        placeholder="Textarea..."
        tabIndex={props.tabIndex}
      />
    </Form.Group>
  );
};

const Email = (props) => {
  return (
    <Form.Group controlId="formBasicEmail">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        type="email"
        className={props.elData.required ? "required" : ""}
        placeholder="Enter email"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
        tabIndex={props.tabIndex}
      />
    </Form.Group>
  );
};

const Signature = (props) => {
  // const formBulder = props.formBuilder

  return (
    <div>
      {props.formBuilder ? (
        <div>
          <h4>Signature</h4>
          {/* <Sign /> */}
        </div>
      ) : (
        <div>
          {/* <Sign elProps={props} /> */}
          <SignPopup elProps={props} />
        </div>
      )}
    </div>
  );
};

const ButtonCustom = (props) => {
  return (
    <>
      <Button
        variant="primary"
        className="btn-block"
        type="submit"
        dangerouslySetInnerHTML={{ __html: props.elData.content }}
        tabIndex={props.tabIndex}
      />
    </>
  );
};

const CheckboxCustom = (props) => {
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });

  return (
    <Form.Check
    // type="checkbox"
    // id={`default-checkbox`}
    // label={props.elData.content}
    >
      <Form.Check.Input
        type="checkbox"
        required={required}
        className={required}
        name={`${props.elData.fieldType}_${props.elData.id}`}
        checked={props.formBuilder ? false : props.formEl.inputValue}
        onChange={(event) => props.handleChange(event, props)}
        tabIndex={props.tabIndex}
      />
      <Form.Check.Label
        className={`${required} ${formBuilder}`}
        dangerouslySetInnerHTML={{ __html: props.elData.content }}
      />
      {/* <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback> */}
    </Form.Check>
  );
};

const RadioButtonCustom = (props) => {
  return (
    <fieldset>
      <Form.Group as={Row} className="row-no-padding radio-button">
        <Col>
          <Form.Label as="legend" column dangerouslySetInnerHTML={{ __html: props.elData.content }} />
        </Col>
        <Col>
          <Row className="row-no-padding">
            <Col>
              <Form.Check
                required={props.elData.required ? "required" : ""}
                className={props.elData.required ? "required" : ""}
                disabled={props.formBuilder ? true : false}
                name={`${props.elData.fieldType}_${props.elData.id}`}
                onChange={(event) => props.handleChange(event, props)}
                type="radio"
                label={props.elData.subItems[0].label}
                id={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[0].sub_id}`}
                value={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[0].sub_id}`}
                tabIndex={props.tabIndex}
              />
            </Col>
            <Col>
              <Form.Check
                className={props.elData.required ? "required" : ""}
                disabled={props.formBuilder ? true : false}
                name={`${props.elData.fieldType}_${props.elData.id}`}
                onChange={(event) => props.handleChange(event, props)}
                type="radio"
                label={props.elData.subItems[1].label}
                id={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[1].sub_id}`}
                value={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[1].sub_id}`}
                tabIndex={props.tabIndex}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>
    </fieldset>
  );
};

const RadioButtonCustomReverse = (props) => {
  return (
    <fieldset>
      <Form.Group as={Row} className="row-no-padding radio-button">
        <Col>
          <Row className="row-no-padding">
            <Col>
              <Form.Check
                required={props.elData.required ? "required" : ""}
                className={props.elData.required ? "required" : ""}
                disabled={props.formBuilder ? true : false}
                name={`${props.elData.fieldType}_${props.elData.id}`}
                onChange={(event) => props.handleChange(event, props)}
                type="radio"
                label={props.elData.subItems[0].label}
                id={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[0].sub_id}`}
                value={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[0].sub_id}`}
              />
            </Col>
            <Col>
              <Form.Check
                className={props.elData.required ? "required" : ""}
                disabled={props.formBuilder ? true : false}
                name={`${props.elData.fieldType}_${props.elData.id}`}
                onChange={(event) => props.handleChange(event, props)}
                type="radio"
                label={props.elData.subItems[1].label}
                id={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[1].sub_id}`}
                value={`${props.elData.fieldType}_${props.elData.id}_subId_${props.formEl.pdfData[1].sub_id}`}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Form.Label as="legend" column dangerouslySetInnerHTML={{ __html: props.elData.content }} />
        </Col>
      </Form.Group>
    </fieldset>
  );
};

const DatePicker = (props) => {
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });

  return (
    <div>
      {props.formBuilder ? (
        <>
          <label htmlFor="startDate" dangerouslySetInnerHTML={{ __html: props.elData.content }}></label>
          <input id="startDate" className="form-control" type="date" disabled />
        </>
      ) : (
        <>
          <label
            className={`${required} ${formBuilder} form-label`}
            htmlFor="startDate_f"
            dangerouslySetInnerHTML={{ __html: props.elData.content }}
          ></label>
          <input
            className={`form-control ${required}`}
            required={required}
            name={`${props.elData.name}_${props.elData.id}`}
            id="startDate_f"
            defaultValue={props.formEl.inputValue}
            type="date"
            onChange={(event) => props.handleChange(event, props)}
            tabIndex={props.tabIndex}
            min={props.elData.min ?? null}
            max={props.elData.max ?? null}
          />
        </>
      )}
    </div>
  );
};

const DateOfBirth = (props) => {
  const [required, setRequired] = useState(() => {
    if (props.elData.required) {
      return "required";
    } else {
      return "";
    }
  });
  const [formBuilder, setFormBuilder] = useState(() => {
    if (props.formBuilder) {
      return "form-builder";
    } else {
      return "";
    }
  });

  return (
    <div>
      {props.formBuilder ? (
        <>
          <label htmlFor="dateOfBirth" dangerouslySetInnerHTML={{ __html: props.elData.content }}></label>
          <input id="dateOfBirth" className="form-control" type="date" disabled />
        </>
      ) : (
        <>
          <label
            className={`${required} ${formBuilder} form-label`}
            htmlFor="dateOfBirth_f"
            dangerouslySetInnerHTML={{ __html: props.elData.content }}
          ></label>
          <input
            className={`form-control ${required}`}
            required={required}
            name={`${props.elData.name}_${props.elData.id}`}
            id="dateOfBirth_f"
            type="date"
            onChange={(event) => props.handleChange(event, props)}
            tabIndex={props.tabIndex}
            max={props.elData.max ?? null}
          />
        </>
      )}
    </div>
  );
};

const Divider = (props) => {
  return (
    <div>
      <hr></hr>
    </div>
  );
};

const PrivacyNotice = (props) => {
  function AaccordionEnabled() {
    return (
      <Accordion>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Privacy Notice</Accordion.Header>
          <Accordion.Body>
            <div className="card-body">
              <p>
                This notice describes how medical information about you may be used and disclosed and how you can get
                access to this information. Please review it carefully.
              </p>
              <p>
                Your protected health information (i.e. individually identifiable information, such as names, dates,
                phone/fax numbers, email addresses, home addresses, social security numbers and demographic data) may be
                used or disclosed by us in one or more of the following respects:
              </p>
              <ul>
                <li>
                  To other health care providers (i.e., your general dentist, oral surgeon, etc.) in connection with our
                  rendering orthodontic treatment to you (i.e., to determine the results of cleanings, surgery, etc.);
                </li>
                <li>
                  To third party payors or spouses (i.e., insurance companies, employers with direct reimbursement,
                  administrators of flexible spending accounts, etc.) in order to obtain payment of your account (i.e.,
                  to determine benefits, dates of payment, etc.)
                </li>
                <li>
                  To certifying, licensing and accrediting bodies (i.e., The American Board of Orthodontics, state
                  dental boards, etc.) in connection with obtaining certification, licensure or accreditation;
                </li>
                <li>Internally, to all staff members who have any role in your treatment;</li>
                <li>
                  To other patients and third parties who may see or overhear incidental disclosures about your
                  treatment alternatives or other health-related benefits and services that may be of interest to you.
                </li>
              </ul>

              <p>
                Any other uses or disclosures of your protected health information will be made only after obtaining
                your written authorization, which you have the right to revoke:
              </p>
              <p>Under the new privacy rules, you have the right to:</p>

              <ul>
                <li>Request restrictions on the use and disclosure of your protected health information;</li>
                <li>Requested confidential communication of your protected health information;</li>
                <li>Inspect and obtain copies of your protected health information through asking us;</li>
                <li>Amend or modify your protected health information in certain circumstances;</li>
                <li>
                  Receive an accounting of certain disclosures made by us of your protected health information; and,
                </li>
                <li>
                  You may, without risk of retaliation, file a complaint as to any violation by us of your privacy
                  rights with us (by submitting inquiries to our Privacy Contact Person at our office address) or the
                  United States Secretary of Health and Human Services (which must be filed within 180 days of the
                  violation)
                </li>
              </ul>

              <p>We have the following duties under the privacy rules:</p>

              <ul>
                <li>
                  By law, to maintain the privacy of protected health information and to provide you with this notice
                  setting forth our legal duties and privacy practices with respect to such information;
                </li>
                <li>To abide by the terms of our Privacy Notice that is currently in effect;</li>
                <li>
                  To advise you of our right to change the terms of this Privacy Notice and to make the new notice
                  provisions effective for all protected health information maintained by use, and that if we do so, we
                  will provide you with a copy of the revised Privacy Notice.
                </li>
              </ul>

              <p>Please note that we are not obligated to:</p>

              <ul>
                <li>
                  Honor any request by you to restrict the use or disclosure of your protected health information;
                </li>
                <li>Amend your protected health information if, for example, it is inaccurate and incomplete: or;</li>
                <li>
                  Provide an atmosphere that is free of the possibility that your protected health information may be
                  incidentally overheard by other patients and third parties.
                </li>
              </ul>

              <p>
                This privacy notice is effective as of the date of your signature. If you have any questions about the
                information in this Notice, please ask for our Privacy Contact Person or direct your questions to the
                persons at our office. Thank you.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
  function AccordionTitle() {
    return <h5>Privacy Notice</h5>;
  }
  if (props.formBuilder) {
    return <AccordionTitle />;
  }
  return <AaccordionEnabled />;
};

FormElements.PatientName = PatientName;
FormElements.PatientEmail = PatientEmail;
FormElements.PatientPhone = PatientPhone;
FormElements.Title = Title;
FormElements.StaticText = StaticText;
FormElements.InputCustom = InputCustom;
FormElements.SelectCustom = SelectCustom;
FormElements.TextareaCustom = TextareaCustom;
FormElements.Email = Email;
FormElements.Signature = Signature;
FormElements.ButtonCustom = ButtonCustom;
FormElements.CheckboxCustom = CheckboxCustom;
FormElements.RadioButtonCustom = RadioButtonCustom;
FormElements.RadioButtonCustomReverse = RadioButtonCustomReverse;
FormElements.DatePicker = DatePicker;
FormElements.DateOfBirth = DateOfBirth;
FormElements.Divider = Divider;
FormElements.PrivacyNotice = PrivacyNotice;

export default FormElements;
