import React, { useContext, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as dayjs from "dayjs";
import AuthContext from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BACKEND_HOST;

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);
  //   const [isExpired, setIsExpired] = useState(null);

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens.access);
    let dayJS = dayjs;
    let isExpired = dayJS.unix(user.exp).diff(dayJS()) < 1;
    console.log({ isExpired });

    if (!isExpired) return req;

    const response = await axios.post(`${baseURL}/api/token/refresh/`, {
      refresh: authTokens.refresh,
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));
    isExpired = false;

    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });
  return axiosInstance;
};

export default useAxios;
