import React, { useState, useEffect, useRef, createRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useStoreState, useStoreActions } from "easy-peasy";
import { v4 as uuidv4 } from "uuid";
import DraglineOverlay from "./DragglineOverlay";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initialDraggableChild = [
  { pdfPage: 1, id: uuidv4(), background: "#8ce8df", size: 100, position: { x: 100, y: 0 } },
  { pdfPage: 1, id: uuidv4(), background: "#8ce8df", size: 100, position: { x: 200, y: 106 } },
  { pdfPage: 1, id: uuidv4(), background: "#afc7fd", size: 102, position: { x: 500, y: 106 } },
  { pdfPage: 1, id: uuidv4(), background: "#d2aff6", size: 150, position: { x: 100, y: 316 } },
  { pdfPage: 2, id: uuidv4(), background: "#fee493", size: 200, position: { x: 480, y: 0 } },
];

export default function PDFView() {
  const [initialDraggableEl, setInitialDraggableEl] = useState(initialDraggableChild);
  // pdf param
  const [numPages, setNumPages] = useState(null);

  // dragging elements param
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const [elToRender, setElToRender] = useState([]);

  const [elementsOnPdf, setElementsOnPdf] = useState([]);
  useEffect(() => {
    setElementsOnPdf(() => {
      let el = formElementsPDF.map((item) => {
        return item.elementOnPdf;
      });
      return el;
    });
    setElToRender(() => {
      let items = [];
      formElementsPDF.map((el) => {
        el.pdfData.map((subElement) => {
          items.push(subElement);
        });
      });
      return items;
    });
  }, [formElementsPDF]);

  const getInitialElementsOnPdf = useStoreActions((actions) => actions.getInitialElementsOnPdf);
  const updElementsOnPdfPsn = useStoreActions((actions) => actions.updElementsOnPdfPsn);

  useEffect(() => {
    getInitialElementsOnPdf();
  }, [getInitialElementsOnPdf]);

  const [keepElInside, setKeepElInside] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [draggFromPageNum, setDraggFromPageNum] = useState(null);
  const [draggToPageNum, setDraggToPageNum] = useState(null);

  useEffect(() => {
    // console.log({ isDragging });
  }, [isDragging]);

  const [elRefs, setElRefs] = useState([]);
  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(numPages)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [numPages]);

  const pdfContainerStyle = {
    // position: "ablsolute",
    position: "relative",
    // width: `${pdfViewerParams.width}px`,
    // height: `${pdfViewerParams.height}px`,
    width: `630px`,
    height: `810px`,
    // boxShadow: "0 0 5px 1px #CCC inset",
    // background: "#F5F8FA",
    // color: "#4A4A4A",
    // margin: 0,
  };
  const pdfElStyle = {
    position: "ablsolute !important",
    // position: "relative",
    // width: `${pdfViewerParams.width}px`,
    // height: `${pdfViewerParams.height}px`,
    width: `600px`,
    height: `700px`,
    // boxShadow: "0 0 5px 1px #CCC inset",
    // background: "#F5F8FA",
    // color: "#4A4A4A",
    // margin: 0,
  };

  const handlePointerOver = (e, pageNum) => {
    if (draggFromPageNum !== null && draggFromPageNum !== pageNum) {
      if (isDragging && draggToPageNum === null) {
        setDraggToPageNum(pageNum);
        // let element move over container
        setKeepElInside(false);
      }
    }
  };

  const hangleStartDragg = (e, b, id, sub_id) => {
    let pageNum = null;
    elToRender.map((item) => {
      if (item.id === id && item.sub_id === sub_id) {
        pageNum = item.pdfPage;
      }
    });
    console.log("START dradding");
    setIsDragging(true);
    setDraggFromPageNum(pageNum);
    // setCurrentDruggParams({ contInd: contInd, elId: elId });
  };

  const handleStopDragg = (e, b, id, sub_id = null) => {
    // console.log({ e });
    console.log("STOP dradding");
    // _updteElPsn(id, b);
    _moveUpdElement(e, b, id, sub_id);
    setIsDragging(false);
    setDraggFromPageNum(null);
    setDraggToPageNum(null);
    setKeepElInside(true);
  };

  const _moveUpdElement = (e, b, id, sub_id) => {
    // let draggedEl = elementsOnPdf.filter((item) => {
    //   return item.id === id;
    // });
    // let pageNum = draggedEl[0].pdfPage;

    if (draggFromPageNum !== null && draggToPageNum !== null) {
      if (isDragging && draggFromPageNum !== draggToPageNum) {
        console.log("start moveElement");
        const { pageX, pageY } = e;

        // const containerEl = draggContRef.current[destinationContId].current;
        // const originY = pageY - _getOffset(containerEl).top;
        const containerEl = elRefs[draggToPageNum - 1].current;
        const originY = pageY - _getOffset(containerEl).top;

        // const originY = pageY;

        // let data = { id: id, x: b.x, y: b.y, pdfPage: draggToPageNum };
        let data = { id: id, sub_id: sub_id, x: b.x, y: originY, pdfPage: draggToPageNum };
        updElementsOnPdfPsn(data);
      }
    } else {
      let data = { id: id, sub_id: sub_id, x: b.x, y: b.y };
      updElementsOnPdfPsn(data);
    }
  };
  const _getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  };

  const _updteElPsn = (id, b) => {
    setInitialDraggableEl(
      initialDraggableEl.map((item) => {
        if (item.id === id) {
          item.position.x = b.x;
          item.position.y = b.y;
        }
        return item;
      })
    );
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // update all windows elements
    window.dispatchEvent(new Event("resize"));
  }

  const pdfUrl = useStoreState((state) => state.pdfUrl);
  // useEffect(() => {
  //   modifyPdf();
  // }, [pdfUrl]);

  return (
    <div>
      <Document
        // file="./adultForm.pdf"
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {/* <Page pageNumber={pageNumber} /> */}
        {Array.from(new Array(numPages), (el, index) => (
          <div ref={elRefs[index]} className="pdf_page_continer" key={`page_${index + 1}`} style={pdfContainerStyle}>
            <Page className="pdf_el" key={`page_${index + 1}`} pageNumber={index + 1} style={pdfElStyle} />
            <DraglineOverlay
              pageNum={index + 1}
              total={numPages}
              handlePointerOver={handlePointerOver}
              hangleStartDragg={hangleStartDragg}
              handleStopDragg={handleStopDragg}
              keepElInside={keepElInside}
              formElementsPDF={formElementsPDF}
              elToRender={elToRender}
            />
            {/* <hr /> */}
          </div>
        ))}
      </Document>
      <p>Total pages: {numPages}</p>
    </div>
  );
}
