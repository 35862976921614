import styled from "styled-components";

/**
 *
 */
export const FormSettingsStyle = styled.div`
  // .wrapper {
  //   display: flex;
  //   align-items: stretch;
  // }

  // .for-edit {
  //   min-width: 300px;
  //   max-width: 300px;
  //   min-height: 80vh;
  //   background: #58628b;
  //   color: #fff;
  //   transition: all 0.3s;
  // }

  // .for-edit.hide {
  //   margin-right: -300px;
  // }
`;
