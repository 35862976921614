import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { useStoreState, useStoreActions } from "easy-peasy";

const ResizeHandle = () => {
  return (
    <span className="react-resizable-handle react-resizable-handle-se"></span>
  );
};

export default function ElementDragResize(props) {
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const updElementsOnPdfSize = useStoreActions(
    (actions) => actions.updElementsOnPdfSize
  );

  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const [element] = useState(() => {
    let el = formElementsPDF.filter((item) => {
      return item.i === props.id;
    });
    return el[0];
  });

  const handleResizeStart = (e, direction) => {
    if (direction === "bottomRight") {
      // console.log(direction);
      e.stopPropagation();
    }
  };
  const handleResizeStop = (e, direction, refToElement, delta) => {
    let newWidth = width + delta.width;
    let newHeight = height + delta.height;
    setWidth(newWidth);
    setHeight(newHeight);
    let data = { w: newWidth, h: newHeight, id: props.id };
    updElementsOnPdfSize(data);
  };

  return (
    <Resizable
      defaultSize={{
        width: width,
        height: height,
      }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      handleComponent={{
        bottomRight: props.flag ? <ResizeHandle /> : "",
      }}
    >
      <div className="box row align-items-end m-0" style={{ width: width + "px", height: height + "px" }}>
        <span className="m-0 p-0">
          {/* w: {width} h: {height} */}
          {element.fieldType}
        </span>
      </div>
    </Resizable>
  );
}
