import React, { useState, useEffect } from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { useStoreState } from "easy-peasy";

const RenderPDF = () => {
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);

  const modifyPdf = async (pdfUrl, setHeight) => {
    const existingPdfBytes = await fetch(
      // "https://pdf-lib.js.org/assets/with_update_sections.pdf"
      pdfUrl
    ).then((res) => res.arrayBuffer());
    // const existingPdfBytes = await fetch(
    //   // "https://pdf-lib.js.org/assets/with_update_sections.pdf"
    //   "/test.pdf"
    // ).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // console.log("modifyPdf test");

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();
    // setWidth(width);
    // setHeight(height);
    // console.log(width, height);

    const removeForms = () => {
      const form = pdfDoc.getForm();
      //   TODO improve flatten feature
      try {
        form.flatten();
      } catch (error) {
        console.log("Error in flatten pdf form");
        console.log(error);
      }
    };
    removeForms();

    const drawText = (page, item) => {
      // Conversion rate for pdf text box size
      const pdfConversionRate = 2.3
      let value = item.inputValue;
      let itemX = item.pdfData[0].position.x;
      let itemY = item.pdfData[0].position.y;
      let boxHeight = item.pdfData[0].height;
      let boxWidth = item.pdfData[0].width;
      let charactersAmmount = item.inputValue.length;
      let size = Math.min(boxWidth*pdfConversionRate/charactersAmmount, boxHeight, 12);

      // Reformat date of birth
      if ((item.fieldType == "dateOfBirth" || item.fieldType == "datePicker") && value){
        console.log(value)
        let YYYY = value.substring(0, 4)
        let DD = value.substring(8, 10)
        let MM = value.substring(5, 7)
        console.log(YYYY)
        console.log(DD)
        console.log(MM)
        value =  MM + '/' + DD + '/' + YYYY
      }

      page.drawText(value, {
        x: itemX,
        y: height - itemY - boxHeight + 2,
        size: size,
        font: helveticaFont,
        color: rgb(0, 0, 0),
        // color: rgb(0.6, 0.6, 0.6),
        //   rotate: degrees(-45),
      });
    };

    const drawSign = async (page, item, pdfDoc) => {
      if (item.inputValue == "" && item.required) {
        alert("Please Sign and insert your signature!");
        return false;
      }
      else if (item.inputValue == "") {
        return false;
      }
      else {
        const pngUrl = item.inputValue;
        const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const pngDims = pngImage.scale(0.5);
        const proportion = pngDims.height / pngDims.width;
        console.log({ pngDims });
  
        let itemX = item.pdfData[0].position.x;
        let itemY = item.pdfData[0].position.y;
        let boxHeight = item.pdfData[0].height;
  
        page.drawImage(pngImage, {
          x: itemX,
          y: height - itemY - boxHeight,
          height: boxHeight,
          width: boxHeight / proportion,
        });
      }
    };

    const drawCheckbox = async (page, item, pdfDoc) => {
      if (item.inputValue === true) {
        const pngUrl = "/media/icons/checkmark-png-25953.png";
        const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const pngDims = pngImage.scale(0.5);
        const proportion = pngDims.height / pngDims.width;
        console.log({ pngDims });

        let itemX = item.pdfData[0].position.x;
        let itemY = item.pdfData[0].position.y;
        let boxHeight = item.pdfData[0].height;

        page.drawImage(pngImage, {
          x: itemX,
          y: height - itemY - boxHeight,
          height: boxHeight,
          width: boxHeight / proportion,
        });
      }
    };

    const drawRadioButton = async (page, item, pdfDoc) => {
      if (item.inputValue !== "") {
        //parse inputValue
        let selectedId = item.inputValue.split("_")[3];
        const pngUrl = "/media/icons/checkmark-png-25953.png";
        const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const pngDims = pngImage.scale(0.5);
        const proportion = pngDims.height / pngDims.width;
        // console.log({ pngDims });
        item.pdfData.map((subItem, ind) => {
          if (selectedId == ind + 1) {
            let itemX = subItem.position.x;
            let itemY = subItem.position.y;
            let boxHeight = subItem.height;

            page.drawImage(pngImage, {
              x: itemX,
              y: height - itemY - boxHeight,
              height: boxHeight,
              width: boxHeight / proportion,
            });
          }
        });
      }
    };

    // pages.map(async (page, ind) => {
    for await (const [ind, page] of pages.entries()) {
      const { width, height } = page.getSize();
      const pageNum = ind + 1;

      for await (const item of formElementsPDF) {
        // check first subElement, so all subElements should be on the same page
        if (item.pdfData[0].pdfPage === pageNum) {
          // console.log(item);
          if (item.fieldType === "signature") {
            await drawSign(page, item, pdfDoc);
          } else if (item.fieldType === "checkbox") {
            await drawCheckbox(page, item, pdfDoc);
          } else if (item.fieldType === "radioButton" || item.fieldType === "radioButtonReverse") {
            await drawRadioButton(page, item, pdfDoc);
          } else if (item.fieldType !== "signature" && item.fieldType !== "checkbox") {
            drawText(page, item);
          }
        }
      }
    }

    const pdfBytes = await pdfDoc.save();
    // const docUrl = URL.createObjectURL(
    //   new Blob(pdfBytes, { type: "application/pdf" })
    // );

    var bytes = new Uint8Array(pdfBytes);
    var blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);

    // setPdfInfo(docUrl);
    return [docUrl, blob, height];
  };
  return modifyPdf;
};
export default RenderPDF;
