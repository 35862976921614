import React, { useState, useEffect } from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { useStoreState } from "easy-peasy";

// let formElementsPDF = [
//   {
//     i: "0",
//     fieldType: "title",
//     layout: {
//       i: "0",
//       x: 0,
//       y: 0,
//       w: 12,
//       h: 2,
//       fieldType: "title",
//     },
//     formElementData: {
//       fieldType: "title",
//       key: "Title",
//       name: "Title",
//       static: true,
//       content: "Title...",
//       value: "Value from element",
//     },
//     isEdit: false,
//     elementOnPdf: {
//       id: "0",
//       background: "#8ce8df38",
//       width: 100,
//       height: 50,
//       position: {
//         x: 20,
//         y: 10,
//       },
//     },
//   },
// ];

const PdfResultView = ({ inputs }) => {
  const [pdfInfo, setPdfInfo] = useState([]);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);

  useEffect(() => {
    modifyPdf();
  }, []);

  const modifyPdf = async () => {
    const existingPdfBytes = await fetch(
      // "https://pdf-lib.js.org/assets/with_update_sections.pdf"
      "/adultForm.pdf"
    ).then((res) => res.arrayBuffer());
    // const existingPdfBytes = await fetch(
    //   // "https://pdf-lib.js.org/assets/with_update_sections.pdf"
    //   "/test.pdf"
    // ).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    console.log("modifyPdf test");

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();
    setWidth(width);
    setHeight(height);
    // console.log(width, height);

    const drawText = (page, item) => {
      let value = item.inputValue;
      let itemX = item.elementOnPdf.position.x;
      let itemY = item.elementOnPdf.position.y;
      let boxHeight = item.elementOnPdf.height;
      let size = 10;

      page.drawText(value, {
        x: itemX,
        y: height - itemY - boxHeight + 2,
        size: size,
        font: helveticaFont,
        color: rgb(1, 0.107, 0.139),
        // color: rgb(0.6, 0.6, 0.6),
        //   rotate: degrees(-45),
      });
    };

    const drawImage = async (page, item, pdfDoc) => {
      const pngUrl = item.inputValue;
      const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      const pngDims = pngImage.scale(0.5);
      const proportion = pngDims.height / pngDims.width;
      console.log({ pngDims });

      let itemX = item.elementOnPdf.position.x;
      let itemY = item.elementOnPdf.position.y;
      let boxHeight = item.elementOnPdf.height;

      page.drawImage(pngImage, {
        x: itemX,
        y: height - itemY - boxHeight,
        height: boxHeight,
        width: boxHeight / proportion,
      });
    };

    // pages.map(async (page, ind) => {
    for await (const [ind, page] of pages.entries()) {
      const { width, height } = page.getSize();
      const pageNum = ind + 1;

      for await (const item of formElementsPDF) {
        if (item.elementOnPdf.pdfPage === pageNum) {
          // console.log(item);
          if (item.fieldType !== "signature") {
            drawText(page, item);
          } else if (item.fieldType === "signature") {
            await drawImage(page, item, pdfDoc);
          }
        }
      }
    }

    // firstPage.drawText("This text was added with React", {
    //   x: 40,
    //   y: height / 2 - 30,
    //   size: 12,
    //   font: helveticaFont,
    //   color: rgb(1, 0.107, 0.139),
    //   // color: rgb(0.6, 0.6, 0.6),
    //   //   rotate: degrees(-45),
    // });

    const pdfBytes = await pdfDoc.save();
    // const docUrl = URL.createObjectURL(
    //   new Blob(pdfBytes, { type: "application/pdf" })
    // );

    var bytes = new Uint8Array(pdfBytes);
    var blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);

    setPdfInfo(docUrl);
  };;

  const iframeStyle = {
    width: "100%",
    // height: "100%",
    height: `${height}px`,
  };

  return <>{<iframe style={iframeStyle} height={height} title="test-frame" src={pdfInfo} type="application/pdf" />}</>;
};;;

export default PdfResultView;
// For example, `pdfBytes` can be:
//   • Written to a file in Node
//   • Downloaded from the browser
//   • Rendered in an <iframe>
// ===================================================================================
