// TODO remove
import React, { useState } from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import GetFormElement from "./base/from-elements";
import { useStoreState } from "easy-peasy";
import { debug } from "easy-peasy";

const ReactGridLayout = WidthProvider(RGL);

export default function NoDraggingLayout(props) {
  const layoutFromStore = useStoreState((state) => state.layout);
  // const formElementsData = useStoreState((state) => state.formElementsData);
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const [layout, setLayout] = useState(() => {
    let newEl = layoutFromStore.map((item, index) => {
      return { ...item, isDraggable: false };
    });
    return newEl;
  });

  const containerStyle = {
    // background: "#e4ecf1",
    background: "#fff",
    border: "1px solid grey",
    minHeight: "300px",
  };
  const innerStyle = {
    // background: "#e4ecf1",
  };

  const createElement = (el) => {
    // el.isDraggable = false;
    // el.layout.isDraggable = false;
    // el.layout.static = true;
    // console.log("createElement");
    // console.log(debug(el.layout));
    // console.log("============================================ ");
    // console.log({ el });
    const i = el.i;
    return (
      <div key={i} data-grid={el.layout} style={innerStyle}>
        <GetFormElement
          formBuilder={false}
          fieldKey={el.i}
          fieldType={el.fieldType}
          elData={el.formElementData}
          elSettingState={false}
          handleChange={props.handleChange}
        />
      </div>
    );
  };

  return (
    <ReactGridLayout
      style={containerStyle}
      layout={layout}
      // onLayoutChange={this.onLayoutChange}
      {...props}
    >
      {_.map(formElementsPDF, (el) => createElement(el))}
      {/* {_.map(formElementsData, (el) => createElement(el))} */}
    </ReactGridLayout>
  );
}

NoDraggingLayout.defaultProps = {
  className: "layout",
  isDraggable: false,
  isResizable: false,
  cols: 12,
  rowHeight: 30,
};
