import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";

// =========================================================
const ReactGridLayout = WidthProvider(RGL);

export default function BasicLayout() {
  const containerStyle = {
    background: "#e4ecf1",
  };
  const innerContStyle = {
    background: "#a2c5db",
  };

  // layout is an array of objects, see the demo for more complete usage
  const layout = [
    { i: "1", x: 0, y: 0, w: 1, h: 2, static: true },
    { i: "2", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
    { i: "3", x: 4, y: 0, w: 1, h: 2 },
    { i: "4", x: 6, y: 0, w: 1, h: 2 },
  ];
  return (
    <ReactGridLayout
      style={containerStyle}
      className="layout"
      layout={layout}
      cols={12}
      rowHeight={30}
      width={1200}
    >
      <div style={innerContStyle} key="1">
        1
      </div>
      <div style={innerContStyle} key="2">
        2
      </div>
      <div style={innerContStyle} key="3">
        3
      </div>
      <div style={innerContStyle} key="4">
        4
      </div>
    </ReactGridLayout>
  );
}
