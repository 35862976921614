import React, { useEffect, useState } from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import GetFormElement from "./base/from-elements";
import { useStoreState, useStoreActions } from "easy-peasy";
import { v4 as uuidv4 } from "uuid";

// =========================================================
const ReactGridLayout = WidthProvider(RGL);

/**
 * This is adjustable grid layout 
 * 
 * `isDraggable:true` 
 */
export default function DNDLayout(props) {
  const editorDisplay = useStoreState((state) => state.editorDisplay);
  const _baseFormItems = useStoreState((state) => state._baseFormItems);
  const startDragElement = useStoreState((state) => state.startDragElement);

  const isUploaded = useStoreState((state) => state.isUploaded);
  const setIsUploaded = useStoreActions((actions) => actions.setIsUploaded);
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const defaultElementsLayout = useStoreState((state) => state.defaultElementsLayout);
  const getInitialElementsOnPdf = useStoreActions((actions) => actions.getInitialElementsOnPdf);
  const updFormElementsPDFLayout = useStoreActions((actions) => actions.updFormElementsPDFLayout);

  // const addFormElementsData = useStoreActions((actions) => actions.addFormElementsData);
  // const removeFormElementsData = useStoreActions((actions) => actions.removeFormElementsData);
  const addFormElementsPDF = useStoreActions((actions) => actions.addFormElementsPDF);
  const removeFormElementsPDF = useStoreActions((actions) => actions.removeFormElementsPDF);
  const setLayoutToStore = useStoreActions((actions) => actions.setLayoutG);

  // local states, used for initialization
  const [layout, setLayout] = useState([]);
  // elementsData contains field name for each element in layout []
  const [elementsData, setElementsData] = useState(formElementsPDF.map((item) => item.formElementData));

  useEffect(() => {
    // update local layout with uploaded data
    if (isUploaded) {
      setLayout(formElementsPDF.map((item) => item.layout));
      setIsUploaded(false);
    }
  }, [isUploaded]);

  useEffect(() => {
    getInitialElementsOnPdf();
    setElementsData(formElementsPDF.map((item) => item.formElementData));
    setLayout(defaultElementsLayout);
  }, [getInitialElementsOnPdf]);

  useEffect(() => {
    // call even 'resize' to fix width of elements in ReactGridLayout
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }, [editorDisplay, props.sidebarState]);

  useEffect(() => {
    // console.log("layout changed", layout);
    setLayoutToStore(layout);
    updFormElementsPDFLayout(layout);
    // updFormElementsDataLayout(layout);
  }, [layout]);

  const containerStyle = {
    // background: "#e4ecf1",
    background: "#fff",
    border: "1px solid grey",
    minHeight: "300px",
  };

  const _filterLayout = (l, i) => {
    let newL = l.filter((item) => {
      return item.i !== i;
    });
    return newL;
  };

  const onRemoveItem = (i) => {
    console.log("removing", i);
    // let newlayout = _.reject(layout, function (item) {
    //   return item.i == i;
    // });
    setLayout(_filterLayout(layout, i));
    setElementsData(_filterLayout(elementsData, i));
    // removeFormElementsData(i);
    removeFormElementsPDF(i);
  };

  const createElement = (el) => {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
    };
    // console.log({ el });
    // make all elements draggable for this view
    const gridLayout = { ...el.layout, isDraggable: true };
    const id = el.formElementData.id;
    const i = el.i;
    const tempKey = el.tempKey;
    const fieldType = el.formElementData.fieldType;
    const lightBlue = "#e4ecf1";
    const lightRed = "#f1d5d5";
    const background = (() => {
      if (el.formElementData.required) {
        return lightRed;
      } else {
        return lightBlue;
      }
    })();
    // console.log({ background });
    return (
      // <div key={`${i}`} data-grid={gridLayout} style={{ background: background }}>
      <div key={`${i}_${tempKey}`} data-grid={gridLayout} style={{ background: background }}>
        <GetFormElement
          formBuilder={true}
          fieldKey={i}
          fieldType={fieldType}
          formEl={el}
          elData={el.formElementData}
          elSettingState={true}
        />
        {/* <p>
          <span className="text"></span>
          {elementsData[index]["fieldType"]}
        </p> */}

        <span className="remove" style={removeStyle} onClick={() => onRemoveItem(i)}>
          x
        </span>
      </div>
    );
  };

  const onDropDragOver = (e) => {
    let newElNum;
    if (_.size(layout) === 0) {
      newElNum = 0;
    } else if (_.size(layout) > 0) {
      let lastElement = layout[_.size(layout) - 1];
      let lastElementNum = parseInt(lastElement.i, 10);
      newElNum = lastElementNum + 1;
    }
    // console.log(e);
    // setOnDropDragOverEvent(e.target);

    const laoutHeigh = (() => {
      let addedFieldType = startDragElement;
      let formElementData = _baseFormItems.filter((item, i) => {
        return item.fieldType === addedFieldType;
      })[0];
      //  if layout preset for the element set his h value
      if (formElementData.layout) {
        props.addedElSize.h = formElementData.layout.minH;
        props.addedElSize.w = formElementData.layout.minW;
        props.addedElSize.minH = formElementData.layout.minH;
        props.addedElSize.minW = formElementData.layout.minW;
      } else {
        props.addedElSize.h = 2;
        props.addedElSize.w = 6;
        props.addedElSize.minH = 1;
        props.addedElSize.minW = 1;
      }
      return props.addedElSize.minH;
    })();

    return {
      i: newElNum.toString(),
      w: props.addedElSize.w,
      h: props.addedElSize.h,
      minH: props.addedElSize.minH,
      minW: props.addedElSize.minW,
      // fieldType: "testFieldType",
    };
  };

  const onDragStop = (layout, oldItem, newItem, placeholder, element) => {
    // console.log("onDragStop");
    setLayout(layout);
    // updFormElementsPDFLayout(layout);
    // updFormElementsDataLayout(layout);
  };
  const onResizeStop = (layout, oldItem, newItem, placeholder, element) => {
    // console.log("onResizeStop");
    setLayout(layout);
    // updFormElementsPDFLayout(layout);
    // updFormElementsDataLayout(layout);
  };

  function _checkIfNotOverAllowedCopyNumber(addingData) {
    const alreadyDropped = formElementsPDF.filter((item, i) => {
      return item.fieldType === startDragElement;
    });

    if (addingData.elementCopyLimit) {
      if (alreadyDropped.length < addingData.elementCopyLimit) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  const onDrop = (layout, layoutItem, _event) => {
    // console.log("onDrop");
    // read dragged from sidebar element data
    let addedFieldType = _event.dataTransfer.getData("text/html");
    // setAddedField(addedField);

    let addingData = _baseFormItems.filter((item, i) => {
      return item.fieldType === addedFieldType;
    })[0];

    let elementCopyAllowed = _checkIfNotOverAllowedCopyNumber(addingData);
    if (!elementCopyAllowed) {
      alert(`This element is already over allowed number of copies: ${addingData.elementCopyLimit}`);
      return;
    }

    let uniqInd = [];
    let uniqueLayout = layout.filter((row, index, self) => {
      let unique = uniqInd.indexOf(row.i) === -1;
      if (unique) {
        uniqInd.push(row.i);
      }
      return unique;
    });

    // set el Id
    let lastLayoutInd = uniqueLayout[_.size(uniqueLayout) - 1].i;
    // addingData.id = lastLayoutInd;

    setLayout(uniqueLayout);

    let addedElement = {
      // i: (_.size(uniqueLayout) - 1).toString(),
      i: lastLayoutInd,
      fieldType: addedFieldType,
      layout: uniqueLayout[_.size(uniqueLayout) - 1],
      formElementData: addingData,
      isEdit: false,
      inputValue: "",
    };
    setElementsData([...elementsData, addedElement]);
    // addFormElementsData(addedElement);
    addFormElementsPDF(addedElement);
  };

  return (
    <>
      <ReactGridLayout
        style={containerStyle}
        className="layout"
        layout={layout}
        isDraggable={props.isDraggable}
        isResizable={props.isResizable}
        cols={12}
        rowHeight={30}
        width={1200}
        onDropDragOver={onDropDragOver}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onDrop={onDrop}
        isDroppable={true}
        compactType={props.compactType}
      >
        {/* {_.map(layout, (el, index) => createElement(el, index))} */}
        {_.map(formElementsPDF, (el) => createElement(el))}
      </ReactGridLayout>
    </>
  );
}

DNDLayout.defaultProps = {
  className: "layout",
  isDraggable: true,
  isResizable: true,
  compactType: null,
  // rowHeight: 30,
  // onLayoutChange: function () {},
  // cols: { lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 },
  addedElSize: { w: 6, h: 2, minH: 1, minW: 1 },
};
