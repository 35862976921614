import React from "react";
import { MdTextFields } from "react-icons/md";
import { MdTitle } from "react-icons/md";
import { MdEmail } from "react-icons/md";
// import { MdDateRange } from "react-icons/md";
import { MdOutlineDateRange } from "react-icons/md";
import { MdOutlineSmartButton } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdRadioButtonChecked } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";
import { BsTextareaResize } from "react-icons/bs";
import { BsHr } from "react-icons/bs";
import { default as El } from "./SidebarElement";

const SidebarElements = () => {
  return (
    <ul className="list-unstyled components p-0">
      <p>Form Elements</p>
      <El fieldType="title" text="Title" icon={<MdTitle />} />
      <El fieldType="staticText" text="Static Text" icon={<MdTextFields />} />
      <El fieldType="signature" text="Signature" icon={<FaFileSignature />} />
      <El fieldType="inputField" text="Input" icon={<BsTextareaResize />} />
      {/* <El fieldType="selectField" text="Select" icon={<BsTextareaResize />} /> */}
      <El fieldType="textarea" text="Textarea" icon={<BsTextareaResize />} />
      {/* <El fieldType="email" text="Email" icon={<MdEmail />} /> */}
      <El fieldType="button" text="Submit" icon={<MdOutlineSmartButton />} />
      <El fieldType="checkbox" text="Checkbox" icon={<MdOutlineCheckBox />} />
      {/* <El fieldType="radioButton" text="Radio Button" icon={<MdRadioButtonChecked />} /> */}
      {/* <El fieldType="date" text="Date" icon={<MdOutlineDateRange />} /> */}
      <El fieldType="divider" text="Divider" icon={<BsHr />} />
    </ul>
  );
};

export default SidebarElements;
