import { createStore, action, thunk, debug } from "easy-peasy";
// import { computed } from "easy-peasy";
import * as dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export default createStore({
  names: [],
  setName: action((state, payload) => {
    state.name = payload;
  }),

  defaultPdfUrl: "/adultForm.pdf",
  pdfUrl: "/adultForm.pdf",
  setPdfUrl: action((state, payload) => {
    state.pdfUrl = payload;
  }),

  startDragElement: "",
  updStartDragElement: action((state, fieldType) => {
    state.startDragElement = fieldType;
  }),

  // use DNDLayout state to build final form layout
  defaultElementsLayout: [
    { i: "0", x: 0, y: 0, w: 12, h: 2, fieldType: "title", pdfPage: 1 },
    { i: "1", x: 0, y: 4, w: 12, h: 2, fieldType: "privacyNotice", pdfPage: 1 },
    // { i: "1", x: 0, y: 1, w: 12, h: 2, fieldType: "staticText" },
  ],
  layout: [],
  setLayoutG: action((state, payload) => {
    state.layout = payload;
  }),

  _baseFormItems: [
    {
      fieldType: "patientName",
      key: "PatientName",
      name: "patient_name",
      static: true,
      content: "Patient name",
      addToPdf: true,
      required: true,
      elementCopyLimit: 1,
    },
    {
      fieldType: "patientEmail",
      key: "PatientEmail",
      name: "patient_email",
      static: true,
      content: "Patient Email",
      addToPdf: true,
      required: true,
      elementCopyLimit: 1,
    },
    {
      fieldType: "patientPhone",
      key: "PatientPhone",
      name: "patient_phone",
      static: true,
      content: "Patient Phone",
      addToPdf: true,
      required: true,
      elementCopyLimit: 1,
    },
    {
      fieldType: "title",
      key: "Title",
      name: "Title",
      // icon: "fas fa-heading",
      static: true,
      content: "Title...",
      addToPdf: false,
    },
    {
      fieldType: "staticText",
      key: "StaticText",
      content: "Placeholder Text...",
      addToPdf: false,
    },
    {
      fieldType: "inputField",
      name: "input",
      key: "InputCustom",
      content: "InputCustom",
      addToPdf: true,
      isNumeric: false,
      required: false,
    },
    {
      fieldType: "selectField",
      name: "select",
      key: "SelectCustom",
      options: ["", "option_1", "option_2"],
      content: "Select item",
      addToPdf: false,
      required: false,
    },
    {
      fieldType: "textarea",
      name: "textarea",
      key: "TextareaCustom",
      content: "Placeholder label ",
      addToPdf: true,
      required: false,
    },
    {
      fieldType: "email",
      name: "email",
      key: "Email",
      content: "Email label ",
      addToPdf: false,
      required: false,
    },
    {
      fieldType: "signature",
      name: "signature",
      key: "Signature",
      content: "Signature",
      addToPdf: true,
      required: true,
      layout: { minH: 4, minW: 6 },
    },
    {
      fieldType: "button",
      key: "ButtonCustom",
      content: "Submit",
      addToPdf: false,
    },
    {
      fieldType: "checkbox",
      key: "CheckboxCustom",
      content: "CheckBox",
      addToPdf: true,
      required: false,
    },
    {
      fieldType: "radioButton",
      key: "RadioButtonCustom",
      content: "Radios label",
      subItems: [
        { sub_id: 1, label: "YES", state: false },
        { sub_id: 2, label: "NO", state: false },
      ],
      addToPdf: true,
      required: false,
    },
    {
      fieldType: "radioButtonReverse",
      key: "RadioButtonCustomReverse",
      content: "Radios label",
      subItems: [
        { sub_id: 1, label: "YES", state: false },
        { sub_id: 2, label: "NO",state: false, },
      ],
      addToPdf: true,
      required: false,
    },
    {
      fieldType: "datePicker",
      name: "datePicker",
      key: "DatePickerKey",
      content: "Date:",
      inputValue: (() => {
        let dayJS = dayjs;
        let dateNow = dayJS().format("YYYY-MM-DD");
        return dateNow;
      })(),
      addToPdf: true,
      required: false,
      min: null,
      max: null,
    },
    {
      fieldType: "dateOfBirth",
      name: "dateOfBirth",
      key: "dateOfBirthKey",
      content: "Date of Birth:",
      inputValue: null,
      addToPdf: true,
      required: false,
      max: (() => {
        let dayJS = dayjs;
        let dateNow = dayJS().format("YYYY-MM-DD");
        return dateNow;
      })(),
    },
    {
      fieldType: "divider",
      key: "Divider",
      content: "Text...",
      addToPdf: false,
    },
    {
      fieldType: "privacyNotice",
      key: "PrivacyNotice",
      content: "Text...",
      addToPdf: false,
      elementCopyLimit: 1,
    },
  ],

  // =============================================================
  // store all form elements data, use it to build final form
  // formElementsData: [],

  // setInitialElementsData: action((state, data) => {
  //   let newEl = state.defaultElementsLayout.map((value, index) => {
  //     console.log(value, index);
  //     let fieldDefaultData = state._baseFormItems.filter((item, i) => {
  //       return item.fieldType === value.fieldType;
  //     });
  //     return {
  //       i: value.i,
  //       fieldType: value.fieldType,
  //       layout: value,
  //       formElementData: fieldDefaultData[0],
  //       isEdit: false,
  //       inputValue: "default",
  //     };
  //   });
  //   state.formElementsData = newEl;
  // }),

  // getInitialElementsData: thunk(async (actions) => {
  //   console.log("getInitialElementsData");
  //   await actions.setInitialElementsData();
  // }),

  // setFormElementsData: action((state, payload) => {
  //   state.formElementsData = payload;
  // }),

  // updFormElementsDataLayout: action((state, layout) => {
  //   let newEl = state.formElementsData.map((value, index) => {
  //     return {
  //       i: value.i,
  //       fieldType: value.fieldType,
  //       layout: layout[index],
  //       formElementData: value.formElementData,
  //       isEdit: value.isEdit,
  //       inputValue: value.inputValue,
  //     };
  //   });
  //   state.formElementsData = newEl;
  // }),

  // updInputValue: action((state, data) => {
  //   let newEl = state.formElementsData.map((item, index) => {
  //     if (item.i === data.i) {
  //       return {
  //         ...item,
  //         inputValue: data.inputValue,
  //       };
  //     } else {
  //       return item;
  //     }
  //   });
  //   state.formElementsData = newEl;
  // }),

  // addFormElementsData: action((state, addedElement) => {
  //   // console.log("in addFormElementsData");
  //   state.formElementsData.push(addedElement);
  //   // return state;
  //   // return {
  //   //   ...state,
  //   //   formElementsData: [...state.formElementsData, addedElement],
  //   // };
  // }),

  // removeFormElementsData: action((state, i) => {
  //   let newElements = state.formElementsData.filter((item) => {
  //     return item.i !== i;
  //   });
  //   state.formElementsData = newElements;
  // }),
  // =============================================================

  // =============================================================
  // ====== Form element Editor
  // =============================================================
  // editor display state
  editorDisplay: false,
  setEditorDisplay: action((state, payload) => {
    state.editorDisplay = payload;
  }),

  // =============================================================
  // ======== PDF params
  // =============================================================
  pdfViewerParams: {
    width: "300px",
    height: "300px",
  },
  updPdfViewerParams: action((state, data) => {
    return {
      ...state,
      pdfViewerParams: {
        width: data.width,
        height: data.height,
      },
    };
  }),

  // =============================================================
  // ======== Elements on top of PDF
  // =============================================================
  isUploaded: false,
  setIsUploaded: action((state, payload) => {
    state.isUploaded = payload;
  }),

  formUser: {},
  formElementsPDF: [],
  _defaultElOnPdf: {
    id: 0,
    pdfPage: 1,
    background: "#8ce8df86",
    width: 100,
    height: 50,
    position: { x: 20, y: 10 },
  },

  setInitialElementsOnPdf: action((state, data) => {
    // state.elementsOnPdf = []; /* destroy data if any */
    let newEl = state.defaultElementsLayout.map((value, index) => {
      // console.log(value, index);
      let _fieldDefaultData = state._baseFormItems.filter((item, i) => {
        return item.fieldType === value.fieldType;
      })[0];
      let fieldDefaultData = { ..._fieldDefaultData, id: value.i };
      // console.log(debug(fieldDefaultData));

      // radiobox data
      let pdfData = [];
      if (_fieldDefaultData.subItems) {
        _fieldDefaultData.subItems.map((item) => {
          console.log(debug(item));
          let subItem = {
            ...state._defaultElOnPdf,
            id: value.i,
            sub_id: item.sub_id,
            addToPdf: fieldDefaultData.addToPdf,
            label: item.label,
          };
          pdfData.push(subItem);
        });
      } else {
        let elementOnPdfData = {
          ...state._defaultElOnPdf,
          id: value.i,
          sub_id: value.i,
          addToPdf: fieldDefaultData.addToPdf,
        };
        pdfData.push(elementOnPdfData);
      }

      // state.elementsOnPdf.push(elementOnPdfData);
      return {
        i: value.i,
        fieldType: value.fieldType,
        layout: value,
        formElementData: fieldDefaultData,
        isEdit: false,
        inputValue: "",
        pdfData: pdfData,
        tempKey: `${value.i}_${uuidv4()}`,
      };
    });
    state.formElementsPDF = newEl;
  }),
  getInitialElementsOnPdf: thunk(async (actions) => {
    // console.log("getInitialElementsOnPdf");
    await actions.setInitialElementsOnPdf();
  }),

  updFormElementsPDFLayout: action((state, layouts) => {
    // console.log({ layouts });
    state.formElementsPDF = state.formElementsPDF.map((item) => {
      layouts.map((layout) => {
        // get index from layout tempKey
        let index = layout.i.split("_")[0];
        if (item.layout.i == index) {
          item.layout = {
            ...item.layout,
            x: layout.x,
            y: layout.y,
            w: layout.w,
            h: layout.h,
          };
        }
      });
      return item;
    });
  }),

  updFormElementsPDF: action((state, payload) => {
    state.formElementsPDF = payload;
  }),

  updFormUser: action((state, payload) => {
    state.formUser = payload;
  }),

  setDefaultInputValue: action((state, data) => {
    let _fieldDefaultData = state._baseFormItems.map((defaultItem, i) => {
      if (defaultItem.inputValue) {
        // set default value
        state.formElementsPDF = state.formElementsPDF.map((item, index) => {
          // item.layout.isDraggable = false;
          let inpValue = "";
          if (item.fieldType === defaultItem.fieldType) {
            inpValue = defaultItem.inputValue;
          }
          return {
            ...item,
            inputValue: inpValue,
          };
        });
      }
    });
  }),

  setTempKey: action((state, data) => {
    // set temp id to avoid conflict with multiform
    state.formElementsPDF = state.formElementsPDF.map((item, index) => {
      return {
        ...item,
        tempKey: uuidv4(),
      };
    });
  }),

  updInputValue: action((state, data) => {
    // let newEl = state.formElementsPDF
    state.formElementsPDF = state.formElementsPDF
      .filter((item) => {
        return item.formElementData.id === data.i;
      })
      .map((item, index) => {
        return {
          ...item,
          inputValue: data.inputValue,
        };
      });
    // state.formElementsPDF = newEl;
  }),

  addFormElementsPDF: action((state, addedElement) => {
    let _fieldDefaultData = state._baseFormItems.filter((item, i) => {
      return item.fieldType === addedElement.fieldType;
    })[0];
    let formElementData = {
      ..._fieldDefaultData,
      id: addedElement.i,
    };
    // pdf data
    // console.log(debug(state.formElementsPDF));
    // console.log("addFormElementsPDF");
    let pdfData = [];
    if (_fieldDefaultData.subItems) {
      _fieldDefaultData.subItems.map((item) => {
        let subItem = {
          ...state._defaultElOnPdf,
          id: addedElement.i,
          sub_id: item.sub_id,
          label: item.label,
          addToPdf: _fieldDefaultData.addToPdf,
        };
        pdfData.push(subItem);
      });
    } else {
      let elementOnPdfData = {
        ...state._defaultElOnPdf,
        id: addedElement.i,
        sub_id: "1",
        label: _fieldDefaultData.content,
        addToPdf: _fieldDefaultData.addToPdf,
      };
      pdfData.push(elementOnPdfData);
    }
    addedElement = { ...addedElement, formElementData: formElementData, pdfData: pdfData };

    state.formElementsPDF.push(addedElement);
    // console.log(debug(state.formElementsPDF));
    // return {
    //   ...state,
    //   formElementsPDF: [
    //     ...state.formElementsPDF,
    //     { ...addedElement, elementOnPdf: elementOnPdfData },
    //   ],
    // };
  }),

  removeFormElementsPDF: action((state, i) => {
    let newElements = state.formElementsPDF.filter((item) => {
      return item.i !== i;
    });
    state.formElementsPDF = newElements;
  }),

  updElementsOnPdfSize: action((state, data) => {
    // console.log(data);
    state.formElementsPDF.map((value) => {
      if (value.i === data.id) {
        value.pdfData.map((subItem) => {
          if (subItem.id === data.id && subItem.sub_id === data.sub_id) {
            subItem.width = data.w;
            subItem.height = data.h;
          }
        });
        return value;
      } else {
        return value;
      }
    });
    return state;
  }),

  updElementsOnPdfPsn: action((state, data) => {
    // console.log({ data });
    state.formElementsPDF.map((value) => {
      if (value.i === data.id) {
        value.pdfData.map((subItem) => {
          if (subItem.id === data.id && subItem.sub_id === data.sub_id) {
            subItem.position.x = data.x;
            subItem.position.y = data.y;
            if (data.pdfPage) {
              subItem.pdfPage = data.pdfPage;
            }
          }
        });
        return value;
      } else {
        return value;
      }
    });
    return state;
  }),

  updOnPdfInputValue: action((state, data) => {
    state.formElementsPDF = state.formElementsPDF.map((item) => {
      if (item.i === data.i) {
        console.log({ item });
        return {
          ...item,
          inputValue: data.inputValue,
        };
      } else {
        return item;
      }
    });
  }),
});
