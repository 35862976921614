import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function DragFromOutsideLayoutFunc(props) {
  const { onLayoutChange = function () {} } = props;

  // const layout = [
  //   { i: "a", x: 0, y: 0, w: 1, h: 3 },
  //   { i: "b", x: 2, y: 1, w: 1, h: 3 },
  //   { i: "c", x: 3, y: 2, w: 1, h: 3 },
  // ];

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layout, setLayout] = useState(props.layout);
  const [layouts, setLayouts] = useState({ lg: layout });
  // const [layouts, setLayouts] = useState({ lg: generateLayout() });

  useEffect(() => {
    setMounted(true);
  }, []);

  function filterLayout(l, i) {
    return l.filter((item) => {
      return item.i !== i;
    });
  }

  function onRemoveItem(i) {
    console.log("layout", layout);
    console.log("removing", i);

    setLayout(filterLayout(layout, i));
    console.log(layout);

    setLayouts({
      lg: filterLayout(layout, i),
    });
    // this.setState({ items: _.reject(this.state.items, { i: i }) });
  }

  function generateDOM() {
    return _.map(layouts.lg, function (l, i) {
      const removeStyle = {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
      };
      // console.log(l);
      return (
        <div
          key={i}
          className={l.static ? "static" : ""}
          style={{ background: "#a2c5db" }}
          data-grid={l}
        >
          <span className="text">{l.i}</span>
          <span
            className="remove"
            style={removeStyle}
            onClick={() => onRemoveItem(l.i)}
          >
            x
          </span>
        </div>
      );
    });
  }

  function onBreakpointChange(breakpoint) {
    setCurrentBreakpoint(breakpoint);
  }

  // onLayoutChange = (layout, layouts) => {
  //   props.onLayoutChange(layout, layouts);
  // };

  function onDrop(layout, layoutItem, _event) {
    console.log("onDrop");
    console.log(_event);
    console.log(layout);
    layoutItem = layout[_.size(layout) - 1];
    console.log(layoutItem);
    // console.log({ ...layoutItem, h: 4, i: "added el" });

    let newLayout = [...layouts.lg, layoutItem];
    setLayout(newLayout);
    setLayouts({ lg: newLayout });
    console.log(layout);
  }

  function onDropDragOver() {
    let lastElement = layout[_.size(layout) - 1];
    let lastElementNum = parseInt(lastElement.i, 10);
    let newElNum = lastElementNum + 1;
    return { i: newElNum.toString(), x: 4, y: 2, w: 1, h: 3 };
  }

  return (
    <div>
      <div>
        Current Breakpoint: {currentBreakpoint} ({props.cols[currentBreakpoint]}{" "}
        columns)
      </div>
      <div>Compaction type: {_.capitalize(compactType) || "No Compaction"}</div>
      {/* <button onClick={onNewLayout}>Generate New Layout</button>
      <button onClick={onCompactTypeChange}>Change Compaction Type</button> */}
      <div
        className="droppable-element"
        draggable={true}
        unselectable="on"
        style={{ background: "#a2c5db" }}
        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        onDragStart={(e) => e.dataTransfer.setData("text/plain", "")}
      >
        Droppable Element (Drag me!)
      </div>
      <ResponsiveReactGridLayout
        {...props}
        layouts={layouts}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        // onDrop={(layout, layoutItem, _event) =>
        //   onDrop(layouts.lg, layoutItem, _event)
        // }
        onDrop={onDrop}
        onDropDragOver={onDropDragOver}
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
        isDroppable={true}
      >
        {generateDOM()}
      </ResponsiveReactGridLayout>
    </div>
  );
}

// function generateLayout() {
//   return _.map(_.range(0, 25), function (item, i) {
//     var y = Math.ceil(Math.random() * 4) + 1;
//     return {
//       x: Math.round(Math.random() * 5) * 2,
//       y: Math.floor(i / 6) * y,
//       w: 2,
//       h: y,
//       i: i.toString(),
//       static: Math.random() < 0.05,
//     };
//   });
// }

DragFromOutsideLayoutFunc.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  layout: [
    { i: "0", x: 0, y: 0, w: 1, h: 3 },
    { i: "1", x: 2, y: 1, w: 1, h: 3 },
    { i: "2", x: 3, y: 2, w: 1, h: 3 },
  ],
};
