import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SubmitForm from "./SubmitForm";
import { useStoreState, useStoreActions } from "easy-peasy";
import "./FillTheForm.css";

const FillFormAndRenderPdf = () => {
  const form_uuid = useParams();

  const [profileColor, setProfileColor] = useState("#1e88e5");
  const [profilelogo, setProfileLogo] = useState("");

  const [currentFormNumber, setCurrentFormNumber] = useState(1);
  const [totalFormNumber, setTotalFormNumber] = useState(1);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [showMobileMessage, setShowMobileMessage] = useState(true);

  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const pdfUrl = useStoreState((state) => state.pdfUrl);
  const setPdfUrl = useStoreActions((actions) => actions.setPdfUrl);
  const backendHost = process.env.REACT_APP_BACKEND_HOST;

  useEffect(() => {
    getForm();
  }, []);

  const getForm = async (event) => {
    const response = await fetch(`${backendHost}/api/pdf_form_link/${form_uuid.uuid}`);
    let data = await response.json();

    if (response.status === 200) {
      let formDatas = data.form_data.map((item) => JSON.parse(item));
      setProfileColor(data.user.bg_color);
      setProfileLogo(`${backendHost}${data.user.image}`);
      localStorage.setItem("profile_bg_color", data.user.bg_color);
      localStorage.setItem("profile_logo", `${backendHost}${data.user.image}`);
      localStorage.setItem("profile_country", data.user.country);
      setData(data);
      setTotalFormNumber(data.form_data.length);
      console.log({ formDatas });
    } else {
      console.log("Error!");
    }
  };

  const _updatePdfUrl = (pdf_file) => {
    if (pdf_file && pdf_file != "") {
      let url = `${backendHost}${pdf_file}`;
      setPdfUrl(url);
    } else {
      setPdfUrl(pdfUrl);
    }
  };

  useEffect(() => {
    // call even 'resize' to fix width of elements in ReactGridLayout
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 30);
  }, [show]);

  return (
    <div className="fill-form-wrapper" style={{ backgroundColor: profileColor }}>
      {showMobileMessage ? (
        <div className="rotate-device">
          <div>For easier form filling please turn your phone</div>
          <button className="btn btn-primary" onClick={() => setShowMobileMessage(false)}>Proceed</button>
        </div>
      ): null}
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <div className="text-center text-white ">
              Form {currentFormNumber}/{totalFormNumber}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-md-center">
            <img src={profilelogo} className="img-fluid mt-1 rounded " style={{ height: "50px" }} />
          </Col>
        </Row>
      </Container>
      <SubmitForm
        data={data}
        setCurrentFormNumber={setCurrentFormNumber}
        currentFormNumber={currentFormNumber}
        totalFormNumber={totalFormNumber}
      />
    </div>
  );
};

FillFormAndRenderPdf.defaultProps = {
  hasPdfView: true,
};
export default FillFormAndRenderPdf;
