import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal, Form } from "react-bootstrap";
import PdfPreviewInModal from "../../comp/pdf/PreviewInModal";
import NoDraggingLayout from "../../comp/forms/RenderedForm";
import { useStoreActions } from "easy-peasy";
import "./FillFormAndRenderPdf.css";

const FillFormAndRenderPdf = ({ hasPdfView }) => {
  const [show, setShow] = useState(false);
  const [inputs, setInputs] = useState({});
  const updInputValue = useStoreActions((actions) => actions.updInputValue);
  const updOnPdfInputValue = useStoreActions((actions) => actions.updOnPdfInputValue);

  const handleChange = (event, elProps) => {
    console.log("handleChange");
    // console.log(elProps.elData.i);
    // console.log(elProps.elData);
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    // updInputValue({ i: elProps.elData.id, inputValue: value });
    updOnPdfInputValue({ i: elProps.elData.id, inputValue: value });
  };

  const handleSubmit = (event) => {
    console.log("submit");
    event.preventDefault();
    alert(JSON.stringify(inputs));
    // alert(inputs);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // call even 'resize' to fix width of elements in ReactGridLayout
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 30);
  }, [show]);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Fill In The Form
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <NoDraggingLayout handleChange={handleChange} />
          </Form>
        </Modal.Body>
        {hasPdfView ? (
          <Modal.Footer>
            <PdfPreviewInModal inputs={inputs} />
          </Modal.Footer>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

FillFormAndRenderPdf.defaultProps = {
  hasPdfView: false,
};
export default FillFormAndRenderPdf;
