import React, { useState, useEffect } from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

let formElementsPDF = [
  {
    i: "0",
    fieldType: "title",
    layout: {
      i: "0",
      x: 0,
      y: 0,
      w: 12,
      h: 2,
      fieldType: "title",
    },
    formElementData: {
      fieldType: "title",
      key: "Title",
      name: "Title",
      static: true,
      content: "Title...",
      value: "Value from element",
    },
    isEdit: false,
    elementOnPdf: {
      id: "0",
      background: "#8ce8df38",
      width: 100,
      height: 50,
      position: {
        x: 20,
        y: 10,
      },
    },
  },
];

const PdfResult = () => {
  const [pdfInfo, setPdfInfo] = useState([]);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    // modifyPdf();
    embedImages();
  }, []);

  async function embedImages() {
    // Create a PDFDocument
    const existingPdfBytes = await fetch(
      // "https://pdf-lib.js.org/assets/with_update_sections.pdf"
      "/adultForm.pdf"
    ).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Add a blank page to the document
    // const page = pdfDoc.addPage();

    const pages = pdfDoc.getPages();
    const page = pages[0];

    const { width, height } = page.getSize();
    setWidth(width);
    setHeight(height);

    const drawImage = async (pdfDoc) => {
      // Fetch PNG image
      const pngUrl = "https://pdf-lib.js.org/assets/minions_banana_alpha.png";
      const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
      // Embed the JPG image bytes and PNG image bytes
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      // Get the width/height of the PNG image scaled down to 50% of its original size
      const pngDims = pngImage.scale(0.5);

      const pages = pdfDoc.getPages();
      const page = pages[0];

      // Draw the PNG image near the lower right corner of the JPG image
      page.drawImage(pngImage, {
        x: page.getWidth() / 2 - pngDims.width / 2 + 75,
        y: page.getHeight() / 2 - pngDims.height,
        width: pngDims.width,
        height: pngDims.height,
      });
      return true;
    };

    // formElementsPDF.map(async (item) => {
    for await (const item of formElementsPDF) {
      await drawImage(pdfDoc);

      console.log(item);
      let value = item.formElementData.value;
      let x = item.elementOnPdf.position.x;
      let y = item.elementOnPdf.position.y;
      let size = 10;

      page.drawText(value, {
        x: x,
        y: height - y - size,
        size: size,
        font: helveticaFont,
        color: rgb(1, 0.107, 0.139),
        // color: rgb(0.6, 0.6, 0.6),
        //   rotate: degrees(-45),
      });
    }

    // // Serialize the PDFDocument to bytes (a Uint8Array)
    // const pdfBytes = await pdfDoc.save()

    // // Trigger the browser to download the PDF document
    // download(pdfBytes, "pdf-lib_image_embedding_example.pdf", "application/pdf");

    const pdfBytes = await pdfDoc.save();
    // const docUrl = URL.createObjectURL(
    //   new Blob(pdfBytes, { type: "application/pdf" })
    // );

    var bytes = new Uint8Array(pdfBytes);
    var blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);

    setPdfInfo(docUrl);
  }

  const modifyPdf = async () => {
    const existingPdfBytes = await fetch(
      // "https://pdf-lib.js.org/assets/with_update_sections.pdf"
      "/test.pdf"
    ).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    console.log("modifyPdf test");

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();
    setWidth(width);
    setHeight(height);
    console.log(width, height);

    formElementsPDF.forEach((item) => {
      console.log(item);
      let value = item.formElementData.value;
      let x = item.elementOnPdf.position.x;
      let y = item.elementOnPdf.position.y;
      let size = 10;

      firstPage.drawText(value, {
        x: x,
        y: height - y - size,
        size: size,
        font: helveticaFont,
        color: rgb(1, 0.107, 0.139),
        // color: rgb(0.6, 0.6, 0.6),
        //   rotate: degrees(-45),
      });
    });
    // firstPage.drawText("This text was added with React", {
    //   x: 40,
    //   y: height / 2 - 30,
    //   size: 12,
    //   font: helveticaFont,
    //   color: rgb(1, 0.107, 0.139),
    //   // color: rgb(0.6, 0.6, 0.6),
    //   //   rotate: degrees(-45),
    // });

    const pdfBytes = await pdfDoc.save();
    // const docUrl = URL.createObjectURL(
    //   new Blob(pdfBytes, { type: "application/pdf" })
    // );

    var bytes = new Uint8Array(pdfBytes);
    var blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);

    setPdfInfo(docUrl);
  };

  const iframeStyle = {
    width: "100%",
    // height: "100%",
    height: `${height}px`,
  };

  return <>{<iframe style={iframeStyle} height={height} title="test-frame" src={pdfInfo} type="application/pdf" />}</>;
};

export default PdfResult;
// For example, `pdfBytes` can be:
//   • Written to a file in Node
//   • Downloaded from the browser
//   • Rendered in an <iframe>
// ===================================================================================
