import React, { useState, useEffect, useRef } from "react";
import { Resizable } from "re-resizable";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useStoreState, useStoreActions } from "easy-peasy";
import * as styles from "./styles.module.css";

const ResizeHandle = () => {
  return <span className="react-resizable-handle react-resizable-handle-se"></span>;
};

export default function ElementDragResize(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const updElementsOnPdfSize = useStoreActions((actions) => actions.updElementsOnPdfSize);

  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const [element, setElement] = useState(() => {
    let el = formElementsPDF.filter((item) => {
      return item.i === props.id;
    });
    return el[0];
  });
  const [elementLabel, setElementLable] = useState("");
  const [tooltipLable, setTooltipLable] = useState("");

  useEffect(() => {
    setElement(() => {
      let el = formElementsPDF.filter((item) => {
        return item.i === props.id;
      });
      return el[0];
    });
    setElementLable(() => {
      let el = formElementsPDF.filter((item) => {
        return item.i === props.id;
      })[0];
      if (el.fieldType == "checkbox") {
        return "";
      } else if (el.fieldType == "radioButton" || el.fieldType == "radioButtonReverse") {
        return "";
      } else {
        return el.formElementData.content;
      }
    });
    setTooltipLable(() => {
      let subEl = element.pdfData.filter((item) => {
        return item.sub_id === props.sub_id;
      })[0];
      // console.log({ element });
      // console.log({ subEl });
      if (element.fieldType === "radioButton" || element.fieldType == "radioButtonReverse") {
        return `${element.formElementData.content} ${subEl.label}`;
      }
      return subEl.label;
    });
  }, [formElementsPDF]);

  const handleResizeStart = (e, direction) => {
    if (direction === "bottomRight") {
      // console.log(direction);
      e.stopPropagation();
    }
  };
  const handleResizeStop = (e, direction, refToElement, delta) => {
    let newWidth = width + delta.width;
    let newHeight = height + delta.height;
    setWidth(newWidth);
    setHeight(newHeight);
    let data = { w: newWidth, h: newHeight, id: props.id, sub_id: props.sub_id };
    console.log({ data });
    updElementsOnPdfSize(data);
  };

  return (
    <Resizable
      defaultSize={{
        width: width,
        height: height,
      }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      handleComponent={{
        bottomRight: props.flag ? <ResizeHandle /> : "",
      }}
    >
      <div
        ref={target}
        onClick={() => setShowTooltip(!showTooltip)}
        onPointerOver={() => setShowTooltip(true)}
        onPointerLeave={() => setShowTooltip(false)}
        className="box row align-items-end m-0"
        style={{ width: width + "px", height: height + "px" }}
      >
        <div className={`m-0 p-0 ${styles.fieldWrapper}`} dangerouslySetInnerHTML={{ __html: elementLabel }}>
          {/* w: {width} h: {height} */}
          {/* {element.formElementData.content} */}
        </div>
        <Overlay target={target.current} show={showTooltip} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              {/* <div dangerouslySetInnerHTML={{ __html: element.formElementData.content }}></div> */}
              <div dangerouslySetInnerHTML={{ __html: tooltipLable }}></div>
            </Tooltip>
          )}
        </Overlay>
      </div>
    </Resizable>
  );
}
