import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Sign from "../../forms/signature/Sign";

const FormElements = {};

const Title = (props) => {
  console.log({ props });
  return (
    <div>
      <h3 dangerouslySetInnerHTML={{ __html: props.elData.content }}></h3>
    </div>
  );
};

const StaticText = (props) => {
  return (
    <div>
      <h5 dangerouslySetInnerHTML={{ __html: props.elData.content }}></h5>
    </div>
  );
};

const InputCustom = (props) => {
  console.log({ props });
  return (
    <Form.Group controlId="formBasicName">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        type="input"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
        placeholder="..."
      />
    </Form.Group>
  );
};

const SelectCustom = (props) => {
  const options = props.elData.options;
  const listItems = options.map((item, i) => (
    <option key={i.toString()} value={item}>
      {item}
    </option>
  ));
  // set initial value to props.handleChange

  return (
    <Form.Group controlId="formGrid">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        as="select"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
        defaultValue="Choose..."
      >
        {listItems}
        {/* <option>Choose...</option>
          <option>...</option> */}
      </Form.Control>
    </Form.Group>
  );
};

const TextareaCustom = (props) => {
  return (
    <Form.Group controlId="formBasicEmail">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        as="textarea"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
        rows={1}
        placeholder="Textarea..."
      />
    </Form.Group>
  );
};

const Email = (props) => {
  return (
    <Form.Group controlId="formBasicEmail">
      <Form.Label dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      <Form.Control
        type="email"
        placeholder="Enter email"
        name={`${props.elData.name}_${props.elData.i}`}
        onChange={(event) => props.handleChange(event, props)}
      />
    </Form.Group>
  );
};

const Signature = (props) => {
  // const formBulder = props.formBuilder

  return (
    <div>
      {props.formBuilder ? (
        <div>
          <h4>Signature</h4>
          {/* <Sign /> */}
        </div>
      ) : (
        <div>
          {/* <h4>Signature</h4> */}
          <Sign elProps={props} />
        </div>
      )}
    </div>
  );
};

const ButtonCustom = (props) => {
  return (
    <>
      <Button
        variant="primary"
        className="btn-block"
        type="submit"
        dangerouslySetInnerHTML={{ __html: props.elData.content }}
      />
    </>
  );
};

const CheckboxCustom = (props) => {
  return <Form.Check type="checkbox" id={`default-checkbox`} label={`default checkbox`} />;
};

const RadioButtonCustom = (props) => {
  return (
    <fieldset>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={2} dangerouslySetInnerHTML={{ __html: props.elData.content }} />

        <Col sm={10}>
          <Form.Check type="radio" label="first radio" name="formHorizontalRadios" id="formHorizontalRadios1" />
          <Form.Check type="radio" label="second radio" name="formHorizontalRadios" id="formHorizontalRadios2" />
        </Col>
      </Form.Group>
    </fieldset>
  );
};

const RadioButtonCustomReverse = (props) => {
  return (
    <fieldset>
      <Form.Group as={Row}>
        <Col sm={10}>
          <Form.Check type="radio" label="first radio" name="formHorizontalRadios" id="formHorizontalRadios1" />
          <Form.Check type="radio" label="second radio" name="formHorizontalRadios" id="formHorizontalRadios2" />
        </Col>
        <Form.Label as="legend" column sm={2} dangerouslySetInnerHTML={{ __html: props.elData.content }} />
      </Form.Group>
    </fieldset>
  );
};

const DatePicker = (props) => {
  return (
    <div>
      <h4>Date</h4>
    </div>
  );
};

const Divider = (props) => {
  return (
    <div>
      <hr></hr>
    </div>
  );
};

FormElements.Title = Title;
FormElements.StaticText = StaticText;
FormElements.InputCustom = InputCustom;
FormElements.SelectCustom = SelectCustom;
FormElements.TextareaCustom = TextareaCustom;
FormElements.Email = Email;
FormElements.Signature = Signature;
FormElements.ButtonCustom = ButtonCustom;
FormElements.CheckboxCustom = CheckboxCustom;
FormElements.RadioButtonCustom = RadioButtonCustom;
FormElements.RadioButtonCustomReverse = RadioButtonCustomReverse;
FormElements.DatePicker = DatePicker;
FormElements.Divider = Divider;

export default FormElements;
