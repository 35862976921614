import React, { useState, useRef } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import { useStoreActions } from "easy-peasy";

export default function PdfViewer() {
  const updPdfViewerParams = useStoreActions((actions) => actions.updPdfViewerParams);
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);
  const scalePreset = "0.8"; /* TODO make it dynamic */

  const { pdfDocument, pdfPage } = usePdf({
    file: "test.pdf",
    scale: scalePreset,
    // scale: "1",
    page,
    canvasRef,
  });
  // console.log(pdfDocument);
  if (pdfDocument) {
    pdfDocument.getPage(1).then((page) => {
      // let viewport = page.getViewport({ scale: 0.8 });
      let viewport = page.getViewport({ scale: scalePreset });
      let data = {
        width: viewport.width,
        height: viewport.height,
      };
      // console.log(canvasRef.current.width);
      updPdfViewerParams(data);

      // console.log(page);
    });
  }

  return (
    <div style={{ position: "absolute" }}>
      {!pdfDocument && <span>Loading...</span>}
      {/* {console.log(pdfDocument)} */}
      <canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav>
          <ul className="pager">
            <li className="previous">
              <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                Previous
              </button>
            </li>
            <li className="next">
              <button disabled={page === pdfDocument.numPages} onClick={() => setPage(page + 1)}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}
