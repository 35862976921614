import styled from "styled-components";

/**
 * Sidebar styles.
 * Contains sidebar/toolbox panel styles
 */
export const Sidebar = styled.div`
  .wrapper {
    display: flex;
    align-items: stretch;
  }

  #sidebar {
    min-width: 180px;
    max-width: 180px;
    min-height: 80vh;
    background: #58628b;
    color: #fff;
    transition: all 0.3s;
  }

  #sidebar.hide {
    margin-left: -180px;
  }

  #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
  }

  #sidebar ul p {
    color: #fff;
    padding: 10px;
    margin: 0;
  }

  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
  }
  #sidebar ul li a:hover {
    color: #58628b;
    background: #fff;
  }

  #sidebar ul li.active > a,
  a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
  }
  ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
  }
`;
