import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, DropdownButton, Dropdown, Form } from "react-bootstrap";
import SidebarElements from "../../comp/sidebarToolbox/SidebarElements";
import { Sidebar as SidebarStyle } from "../../comp/sidebarToolbox/styles/Sidebar";
import { FaPlus } from "react-icons/fa";
import DNDLayout from "../../comp/forms/DNDLayout";
import PDFView from "./PDFView";
import FillFormAndRenderPdf from "./FillFormAndRenderPdf";
import FormSettingPanel from "../../comp/forms/FormSettingPanel";
import PdfPreviewInModal from "../../components/pdf/PreviewInModal";
import AuthContext from "../../context/AuthContext";
import useAxios from "../../utils/useAxios";
import { useStoreState, useStoreActions } from "easy-peasy";
import "./Main.css";

export default function BuildFromPdf() {
  const [sidebarState, setSidebarState] = useState(true);
  const [sidebarVisibility, setSidebarVisibility] = useState("hide");

  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const updFormUser = useStoreActions((actions) => actions.updFormUser);
  const setIsUploaded = useStoreActions((actions) => actions.setIsUploaded);

  const editorDisplay = useStoreState((state) => state.editorDisplay);
  const hasPdfView = true;

  // to backend
  let { authTokens, logoutU } = useContext(AuthContext);
  let { user, logoutUser } = useContext(AuthContext);
  const [forms, setForms] = useState([]);
  const [form, setForm] = useState([]);
  const [formName, setFormName] = useState("");
  const [formId, setFormId] = useState(null);
  const [formJustUpdated, setFormJustUpdated] = useState(false);
  const [formNeedToUpdated, setFormNeedToUpdated] = useState(false);
  const [formIsNew, setFormIsNew] = useState(true);

  // const backendHost = useStoreState((state) => state.backendHost);
  const backendHost = process.env.REACT_APP_BACKEND_HOST;
  const defaultPdfUrl = useStoreState((state) => state.defaultPdfUrl);
  const setPdfUrl = useStoreActions((actions) => actions.setPdfUrl);
  const setTempKey = useStoreActions((actions) => actions.setTempKey);
  let apiAxios = useAxios();

  useEffect(() => {
    getForms();
    // set default form from Store
    setForm(formElementsPDF);
  }, []);
  useEffect(() => {
    // update form from Store
    setForm(formElementsPDF);
    // TODO implement some animation
    // check if form need to update
    if (formId && !formJustUpdated) {
      setFormNeedToUpdated(true);
      console.log("Form need to update");
    }
    setFormJustUpdated(false);
  }, [formElementsPDF]);

  const _cleanForm = (formData) => {
    return formData.map((item) => {
      return {
        ...item,
        inputValue: "",
      };
    });
  };
  const getForms = async () => {
    let response = await apiAxios.get(`/api/pdf_forms/`);
    if (response.status === 200) {
      setForms(response.data);
    }
  };

  const updatePdfUrl = (data) => {
    if (data.pdf_file_name && data.pdf_file_name != "") {
      let url = `${backendHost}${data.pdf_file}`;
      setPdfUrl(url);
    } else {
      setPdfUrl(defaultPdfUrl);
    }
  };

  const loadSavedForm = async (id) => {
    let response = await apiAxios.get(`/api/pdf_form/${id}`);
    let data = response.data;

    if (response.status === 200) {
      console.log(data);
      setFormName(data.form_name);
      setFormId(data.id);
      let formData = JSON.parse(data.form_data);
      // setForm(formData);
      updatePdfUrl(data);
      setFormJustUpdated(true);
      setFormIsNew(false);
      updFormElementsPDF(formData);
      updFormUser(data.user)
      setTempKey();
      setIsUploaded(true);
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }
  };

  const updateForm = async () => {
    const file_upload_form = document.getElementById("file_upload_form");
    const formData = new FormData(file_upload_form);
    // get filename
    const fileInput = document.querySelector("input[type=file]");
    const path = fileInput.value;
    const fileName = path.split(/(\\|\/)/g).pop();
    formData.append("pdf_file_name", fileName);
    formData.append("form_name", formName);
    const cleanedForm = _cleanForm(form);
    console.log({ cleanedForm });
    formData.append("form_data", JSON.stringify(cleanedForm));
    console.log({ formData });

    let response = await apiAxios.put(`/api/pdf_form/${formId}/`, formData);
    let data = await response.data;

    if (response.status === 200) {
      console.log(response.data);
      console.log(data);
      const { pdf_file_name, pdf_file } = data;
      updatePdfUrl(data);
      setFormIsNew(false);
    } else if (response.status === 400) {
      console.log("Bad request");
      console.log(data);
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    } else if (response.status === 500) {
      console.log("There was a problem with the server");
    } else {
      console.log({ response });
    }
  };

  const saveForm = async () => {
    const file_upload_form = document.getElementById("file_upload_form");
    const formData = new FormData(file_upload_form);
    // get filename
    const fileInput = document.querySelector("input[type=file]");
    const path = fileInput.value;
    const fileName = path.split(/(\\|\/)/g).pop();
    formData.append("pdf_file_name", fileName);
    formData.append("form_name", formName);
    const cleanedForm = _cleanForm(form);
    // console.log({ cleanedForm });
    formData.append("form_data", JSON.stringify(cleanedForm));

    let response = await apiAxios.post(`/api/pdf_form/`, formData);
    let data = await response.data;

    if (response.status === 200) {
      console.log(response.data);
      console.log(data);
      const { pdf_file_name, pdf_file } = data;
      updatePdfUrl(data);
      setFormId(data.id);
      setFormIsNew(false);
      getForms();
    } else if (response.status === 400) {
      console.log("Bad request");
      console.log(data);
    } else if (response.status === 500) {
      console.log("There was a problem with the server");
    } else {
      console.log({ response });
    }
  };

  function toggleSidebar(e) {
    setSidebarState(!sidebarState);
    if (sidebarState) {
      setSidebarVisibility("");
    } else {
      setSidebarVisibility("hide");
    }
  }

  // ================================== PDF func ============================
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose file");

  function onChange(e) {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    console.log(e.target.files[0]);
    console.log(e.target.files[0].name);
  }

  return (
    <SidebarStyle>
      <main style={{ padding: "0" }}>
        <div className="wrapper">
          <nav id="sidebar" className={sidebarVisibility}>
            <SidebarElements />
          </nav>

          <div id="content" className="w-100">
            <nav className="navbar navbar-expand-lg navbar-light bg-light  p-1">
              <button onClick={toggleSidebar} type="button" className="btn outline-primary">
                <span>
                  Add Elements <FaPlus />
                </span>
              </button>
              <FillFormAndRenderPdf hasPdfView={hasPdfView} />
              {/* testing element */}
              {/* <PdfPreviewInModal /> */}
            </nav>
            <Container fluid className="w-100">
              <Row>
                <Col>
                  {/* <h6>Custom Form </h6> */}
                  {forms && (
                    <DropdownButton
                      className="mb-1"
                      variant="outline-secondary"
                      id="dropdown-form-button"
                      title="Select your form"
                    >
                      {forms.map((form) => (
                        <Dropdown.Item key={form.id} onClick={() => loadSavedForm(form.id)}>
                          {form.form_name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  )}
                  <Form.Group controlId="formBasicName">
                    {/* <Form.Label /> */}
                    <Form.Control
                      type="input"
                      name="form_name_input"
                      onChange={(event) => setFormName(event.target.value)}
                      placeholder="Form Name"
                      required={true}
                      value={formName}
                    />
                  </Form.Group>
                  {/* <PdfCustom sidebarState={sidebarState} /> */}
                  <DNDLayout sidebarState={sidebarState} />
                  {formIsNew ? (
                    <Button variant="primary" onClick={saveForm}>
                      Save your form
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={updateForm}>
                      Update your form
                    </Button>
                  )}
                </Col>
                <Col>
                  <div style={{ position: "relative" }}>
                    <div>
                      <h6>Original PDF </h6>
                      <form
                        id="file_upload_form"
                        action={`${backendHost}/api/pdf_form/`}
                        method="post"
                        encType="multipart/form-data"
                        // onSubmit={onSubmit}
                      >
                        <div className="mb-4">
                          <input
                            type="file"
                            className="custom-pdf-input"
                            name="pdf_file"
                            id="customPdf"
                            onChange={onChange}
                          />
                          <label className="custom-pdf-label" htmlFor="customPdf" />
                          {/* <input type="submit" value="Upload" className="btn btn-primary btn-block mt-4" /> */}
                        </div>
                      </form>
                    </div>
                    <hr />

                    {/* <PDFView_2 /> */}
                    <PDFView />
                    {/* <DraglineOverlay /> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <FormSettingPanel editorDisplay={editorDisplay} />
        </div>
      </main>
    </SidebarStyle>
  );
}
