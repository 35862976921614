import React from "react";
import { GoSettings } from "react-icons/go";
import { useStoreState, useStoreActions } from "easy-peasy";

export default function SettingsBtn(props) {
  const iconStyle = {
    position: "absolute",
    right: "20px",
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  };
  const editorDisplay = useStoreState((state) => state.editorDisplay);
  const setEditorDisplay = useStoreActions((actions) => actions.setEditorDisplay);
  // const formElementsData = useStoreState((state) => state.formElementsData);
  // const setFormElementsData = useStoreActions(
  //   (actions) => actions.setFormElementsData
  // );
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);

  const toggleSettings = () => {
    if (editorDisplay) {
      /* if true-set to false */
      setEditorDisplay(!editorDisplay);
      updFormElementsPDF(
        formElementsPDF.map((item) => {
          // console.log(item);
          item.isEdit = false;
          return item;
        })
      );
    } else {
      setEditorDisplay(!editorDisplay);
      updFormElementsPDF(
        formElementsPDF.map((item) => {
          // console.log(item);
          if (item.i === props.fieldKey) {
            item.isEdit = true;
          }
          return item;
        })
      );
    }
  };

  // console.log(props);
  return (
    <div style={iconStyle} className="settingsBtn">
      <span onClick={toggleSettings}>
        <GoSettings />
      </span>
    </div>
  );
}
