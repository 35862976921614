import React, { useRef, useState } from "react";
import { Button, Tabs, Tab, Col, Nav, Row, Form, Container } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import html2canvas from "html2canvas";
import * as styles from "./styles.module.css";
import Signature from "../../comp/forms/signature/SignPopup";

const SignatureDemo = () => {
  const [currentWord, setCurrentWord] = useState("");
  const currentWordInput = useRef(null);
  const signInput = useRef(null);
  const saveS = () => {
    console.log("Save S");
    const dataImgBase64 = signInput.current.toDataURL();
    console.log(dataImgBase64);
    // save to user profile or db
  };
  const clearS = () => {
    console.log("Clear S");
    signInput.current.clear();
  };

  async function insertSign(e) {
    console.log("insert");
    console.log({ currentWord });
    let dataImgBase64 = "";
    const canvas = await html2canvas(currentWordInput.current, {
      backgroundColor: null,
      // height: 50,
      // width: 300,
    });

    console.log(canvas);
    dataImgBase64 = canvas.toDataURL();
    if (dataImgBase64 != "") {
      signInput.current.fromDataURL(dataImgBase64);
      console.log(dataImgBase64);
    }
  }

  return (
    <>
      <Signature />
      <hr />
      {/* <div
        style={{
          border: "1px solid rgb(128,128,128)",
          width: "50%",
          minHeight: 200,
          margin: "auto",
          marginTop: 20,
        }}
      >
        <Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Draw</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Type</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <SignatureCanvas
                      ref={signInput}
                      penColor="green"
                      // backgroundColor="rgba(1,1,1,0.1)"
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas",
                      }}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Form.Group controlId={`form_input_signature`}>
                      <Form.Label className="" />
                      <div className={styles.signatureWrapper}>
                        <span className={styles.signatureSpan} ref={currentWordInput}>
                          {currentWord}
                        </span>
                      </div>
                      <Form.Control
                        className={styles.inputSignatureField}
                        maxLength="10"
                        // size="lg"
                        type="input"
                        name={`signature_res`}
                        onChange={(e) => setCurrentWord(e.target.value)}
                        placeholder="type  here"
                      />
                      <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                    </Form.Group>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className="m-1" onClick={insertSign}>
            Insert
          </Button>
          <Button variant="outline-secondary" className="m-1" onClick={clearS}>
            Clear
          </Button>
        </div>
      </div> */}

      {/* <hr />
      <div
        style={{
          border: "1px solid rgb(128,128,128)",
          width: 500,
          margin: "auto",
          marginTop: 20,
        }}
      >
        <SignatureCanvas
          ref={signInput}
          penColor="green"
          // backgroundColor="rgba(1,1,1,0.1)"
          canvasProps={{
            width: 500,
            height: 200,
            className: "sigCanvas",
          }}
        />
      </div>
      <div
        style={{
          border: "1px solid rgb(128,128,128)",
          width: 500,
          margin: "auto",
        }}
      >
        <Button variant="outline-primary" className="m-1" onClick={saveS}>
          Save
        </Button>
        <Button variant="outline-secondary" className="m-1" onClick={clearS}>
          Clear
        </Button>
      </div> */}
    </>
  );
};

export default SignatureDemo;
