import React from "react";
import { useEffect, useState, useRef, createRef } from "react";
import { DraggableContainer, DraggableChild } from "react-dragline";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const initialChildren = [
  { id: 1, background: "#8ce8df", size: 100, position: { x: 100, y: 0 } },
  { id: 2, background: "#8ce8df", size: 100, position: { x: 200, y: 106 } },
  { id: 3, background: "#afc7fd", size: 102, position: { x: 500, y: 106 } },
  { id: 4, background: "#d2aff6", size: 150, position: { x: 100, y: 316 } },
  { id: 5, background: "#fee493", size: 200, position: { x: 480, y: 376 } },
];
const initialChildren_2 = [{ id: 1, background: "#8ce8df", size: 100, position: { x: 100, y: 0 } }];
const initialDraggableChild = [
  { pdfPage: 1, id: uuidv4(), background: "#8ce8df", size: 100, position: { x: 100, y: 0 } },
  { pdfPage: 1, id: uuidv4(), background: "#8ce8df", size: 100, position: { x: 200, y: 106 } },
  { pdfPage: 1, id: uuidv4(), background: "#afc7fd", size: 102, position: { x: 500, y: 106 } },
  { pdfPage: 1, id: uuidv4(), background: "#d2aff6", size: 150, position: { x: 100, y: 316 } },
  { pdfPage: 2, id: uuidv4(), background: "#fee493", size: 200, position: { x: 480, y: 0 } },
];

export default function DragAndDropTutorial(props) {
  const { onPointerMove, onDragMove } = props;

  const draggContRef = useRef([]);

  const [keepElInside, setKeepElInside] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [elDraggPsn, setElDraggPsn] = useState({ x: null, y: null });

  const [currentDruggParams, setCurrentDruggParams] = useState({ contInd: null, elId: null });
  const [initialDraggableEl, setInitialDraggableEl] = useState(initialDraggableChild);
  const [destinationContId, setDestinationContId] = useState(null);
  //   const [elements, setElementes] = useState(initialChildren);
  //   const [elements_2, setElementes_2] = useState(initialChildren_2);
  const [numOfContainer, setNumOfContainer] = useState(2);

  const containerStyle = {
    position: "relative",
    height: 600,
    boxShadow: "0 0 5px 1px #CCC inset",
    background: "#F5F8FA",
    color: "#4A4A4A",
    margin: 20,
  };

  const childStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "move",
  };

  const moveElement = (e, id) => {
    // console.log("before moveElement");
    let originContId = currentDruggParams.contInd;
    const destinationCont = draggContRef.current[destinationContId];

    if (isDragging && destinationContId !== null && originContId != destinationContId && originContId != null) {
      console.log("start moveElement");
      console.log({ e });

      const { pageX, pageY } = e;
      const containerEl = draggContRef.current[destinationContId].current;
      const originY = pageY - _getOffset(containerEl).top;

      setInitialDraggableEl(
        initialDraggableEl.map((item) => {
          if (item.id === id) {
            // item.position.x = item.position.x + item.size / 2;
            item.position.y = originY - item.size / 2;
            item.pdfPage = destinationContId + 1;
            console.log({ item });
            return item;
          }
          return item;
        })
      );
    }
  };

  const updteElPsn = (id, b) => {
    setInitialDraggableEl(
      initialDraggableEl.map((item) => {
        if (item.id === id) {
          item.position.x = b.x;
          item.position.y = b.y;
        }
        return item;
      })
    );
  };

  const handleStop = (e, b, id) => {
    console.log("handleStop");
    updteElPsn(id, b);
    moveElement(e, id);
    setIsDragging(false);
    setKeepElInside(true);
    setDestinationContId(null);
    setCurrentDruggParams({ contInd: null, elId: null });
    setElDraggPsn({ x: b.x, y: b.y });
    console.log({ elDraggPsn });
  };

  const hangleStartDragg = (e, b, contInd, elId) => {
    console.log("start dradding");
    setIsDragging(true);
    setCurrentDruggParams({ contInd: contInd, elId: elId });
  };

  const letElMoveOver = (e, contKey) => {
    let destinationContId = contKey;
    let originContId = currentDruggParams.contInd;
    let elementId = currentDruggParams.elId;
    const containerEl = draggContRef.current[contKey].current;

    if (isDragging && originContId != destinationContId && originContId != null) {
      console.log("letElMoveOver");
      setKeepElInside(false);
      setDestinationContId(contKey);
    }
  };
  const handlePointerOver = (e, i) => {
    let contKey = i;
    // moveElement(e, contKey);
    // setDestinationContId(contKey);
    letElMoveOver(e, contKey);
  };

  const handlePointerMove = (e) => {
    console.log("=========== handlePointerMove");
    const { pageX, pageY } = e;
    // console.log({ pageX });
    console.log({ pageX });
    console.log({ pageY });

    // const div = document.querySelector("#root > div > div > div > div:nth-child(2) > div");
    const div = draggContRef.current[1].current;

    console.log(_getOffset(div));
    const originX = pageX - _getOffset(div).left;
    const originY = pageY - _getOffset(div).top;
    console.log({ originX });
    console.log({ originY });
  };

  const _getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  };

  const arrLength = numOfContainer;
  if (draggContRef.current.length !== arrLength) {
    // add or remove refs
    draggContRef.current = Array(arrLength)
      .fill()
      .map((_, i) => draggContRef.current[i] || createRef());
  }

  return (
    <div>
      <style>{".active { opacity: .5;} .item{z-index: 100;}"}</style>
      {_.times(numOfContainer, (i) => (
        <div
          key={i}
          ref={draggContRef.current[i]}
          className="draggWrapper"
          onClick={handlePointerMove}
          onPointerOver={(e) => handlePointerOver(e, i)}
        >
          <DraggableContainer key={i} style={containerStyle} limit={keepElInside}>
            {initialDraggableEl
              .filter((item) => item.pdfPage === i + 1)
              .map(({ id, background, size, position }) => (
                <DraggableChild
                  key={id}
                  defaultPosition={position}
                  onStart={(e, b, contInd = i, elId = id) => hangleStartDragg(e, b, contInd, elId)}
                  onStop={(e, b) => handleStop(e, b, id)}
                >
                  <div
                    className="item"
                    style={{
                      ...childStyle,
                      background,
                      width: size,
                      height: size,
                    }}
                  >
                    <span>size: {size}</span>
                    <span>drag me</span>
                  </div>
                </DraggableChild>
              ))}
          </DraggableContainer>
        </div>
      ))}
      {/* <div
        className="draggWrapper"
        onClick={handlePointerMove}
        onPointerOver={(e, key = 2) => handlePointerOver(e, key)}
      >
        <DraggableContainer style={containerStyle} limit={true}>
          {elements_2.map(({ id, background, size, position }) => (
            <DraggableChild
              key={id}
              defaultPosition={position}
              onStart={hangleStartDragg}
              onStop={(e, b) => handleStop(e, b, id)}
            >
              <div
                className="item"
                style={{
                  ...childStyle,
                  background,
                  width: size,
                  height: size,
                }}
              >
                <span>size: {size}</span>
                <span>drag me</span>
              </div>
            </DraggableChild>
          ))}
        </DraggableContainer>
      </div> */}
    </div>
  );
}

DragAndDropTutorial.defaultProps = {
  // onPointerDown: () => {},
  // onPointerUp: () => {},
  //   onPointerMove: () => {},
};

// export default Example;
// ReactDOM.render(<Example />, container)
