import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

const LoginAs = () => {
  const navigate = useNavigate();
  // const backendHost = useStoreState((state) => state.backendHost);
  const backendHost = process.env.REACT_APP_BACKEND_HOST;
  let [users, setUsers] = useState([]);
  let [userToLogin, setUserToLogin] = useState("");
  let { authTokens, logoutUser, setAuthTokens } = useContext(AuthContext);
  let { user, setUser, logOutUser } = useContext(AuthContext);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    let response = await fetch(`${backendHost}/api/login_as/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setUsers(data);
      console.log(data);
    } else if (response.status === 403) {
      alert("Sorry you are not authorized to login_as, contact your admin for more details");
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }
  };

  const handleUserLogin = async () => {
    let response = await fetch(`${backendHost}/api/login_as/${userToLogin}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      console.log(data);
      setAuthTokens(data.credentials);
      // setUser(data.username);
      setUser(jwt_decode(data.credentials.access));
      localStorage.setItem("authTokens", JSON.stringify(data.credentials));
      navigate("/");
    } else if (response.status === 403) {
      alert("Sorry you are not authorized to login_as, contact your admin for more details");
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setUserToLogin(e.target.value);
  };

  return (
    <div className="container">
      <p>You are logged as: {user.username} !</p>
      {/* <p>is superuser status: {user.is_superuser.toString()}</p> */}

      {/* <label htmlFor="usersList" className="form-label">
        Select user
      </label> */}
      <input
        onChange={handleChange}
        className="form-control"
        list="datalistOptions"
        id="usersList"
        placeholder="Select user to login"
      />
      <datalist id="datalistOptions">
        {users
          .filter((u) => {
            return u.username !== user.username;
          })
          .map((u) => (
            <option key={u.id}>{u.username}</option>
          ))}
      </datalist>

      <button onClick={handleUserLogin} disabled={userToLogin == "" ? true : false} className="btn btn-primary mt-2">
        Login As user
      </button>
    </div>
  );
};

export default LoginAs;
