import React from "react";
import { useState, useEffect } from "react";
import { DraggableContainer, DraggableChild } from "react-dragline";
import { useStoreState, useStoreActions } from "easy-peasy";
import _ from "lodash";
import ElementDragResize from "../../comp/pdf/ElementDragResize";

export default function DraglineOverlay(props) {
  const [flag, setFlag] = useState(false);

  // const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  // const elementsOnPdf = useStoreState((state) => state.elementsOnPdf);
  // const [elementsOnPdf, setElementsOnPdf] = useState([]);
  // const [initialDraggableEl, setInitialDraggableEl] = useState(props.initialDraggableEl);
  // const getInitialElementsOnPdf = useStoreActions((actions) => actions.getInitialElementsOnPdf);
  // const updElementsOnPdfPsn = useStoreActions((actions) => actions.updElementsOnPdfPsn);

  // useEffect(() => {
  //   getInitialElementsOnPdf();
  // }, [getInitialElementsOnPdf]);

  // useEffect(() => {
  //   setElementsOnPdf(() => {
  //     let el = formElementsPDF.map((item) => {
  //       return item.elementOnPdf;
  //     });
  //     return el;
  //   });
  //   console.log({ elementsOnPdf });
  // }, [formElementsPDF]);

  const containerStyle = {
    position: "relative",
    width: `630px`,
    height: `810px`,
    boxShadow: "0 0 5px 1px #CCC inset",
    // background: "#F5F8FA",
    color: "#4A4A4A",
    margin: 0,
  };

  const childStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "move",
  };

  const renderDraggableChild = (el) => {
    let background = el.background;
    return (
      <DraggableChild
        key={`group_id_${el.id}_sub_id${el.sub_id}`}
        defaultPosition={el.position}
        onStart={(e, b) => props.hangleStartDragg(e, b, el.id, el.sub_id)}
        onStop={(e, b) => props.handleStopDragg(e, b, el.id, el.sub_id)}
      >
        <div
          className="item"
          style={{
            ...childStyle,
            background,
            // width: size,
            // height: size,
          }}
          onMouseEnter={() => {
            setFlag(true);
          }}
          onMouseLeave={() => {
            setFlag(false);
          }}
        >
          <ElementDragResize flag={flag} width={el.width} height={el.height} id={el.id} sub_id={el.sub_id} />
          {/* <span>drag me</span> */}
        </div>
      </DraggableChild>
    );
  };

  return (
    <div
      key={props.pageNum}
      className="dragOverlay"
      style={{ position: "absolute" }}
      onPointerOver={(e) => props.handlePointerOver(e, props.pageNum)}
    >
      <style>{".active { opacity: .5;} .item{z-index: 100;}"}</style>

      <DraggableContainer key={props.pageNum} style={containerStyle} limit={props.keepElInside}>
        {/* {initialDraggableEl */}
        {props.elToRender
          .filter((item) => item.addToPdf === true)
          .filter((item) => item.pdfPage === props.pageNum)
          .map((item) => renderDraggableChild(item))}
      </DraggableContainer>
    </div>
  );
}
