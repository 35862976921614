import React, { useEffect, useState } from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import GetFormElement from "./base/from-elements";
import { useStoreState, useStoreActions } from "easy-peasy";
// import { debug } from "easy-peasy";
// import { v4 as uuidv4 } from "uuid";

const ReactGridLayout = WidthProvider(RGL);

export default function NoDraggingLayout(props) {
  const layoutFromStore = useStoreState((state) => state.layout);
  // const formElementsData = useStoreState((state) => state.formElementsData);
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const formUser = useStoreState((state) => state.formUser);
  const setDefaultInputValue = useStoreActions((actions) => actions.setDefaultInputValue);
  const setTempKey = useStoreActions((actions) => actions.setTempKey);
  const [layout, setLayout] = useState(() => {
    let newLayout = formElementsPDF.map((item, index) => {
      return { ...item.layout, isDraggable: false };
    });
    return newLayout;
  });

  useEffect(() => {
    setTempKey();
    // set default inputValue if any
    setDefaultInputValue();
    // updFormElementsPDF(
    //   formElementsPDF.map((item) => {
    //     item.layout.isDraggable = false;
    //     return item;
    //   })
    // );
  }, []);

  useEffect(() => {
    setLayout(() => {
      let newLayout = formElementsPDF.map((item, index) => {
        return { ...item.layout, isDraggable: false };
      });
      return newLayout;
    });
  }, [formElementsPDF]);

  const containerStyle = {
    // background: "#e4ecf1",
    background: "#fff",
    border: "1px solid grey",
    minHeight: "300px",
  };
  const innerStyle = {
    // background: "#e4ecf1",
  };

  const createElement = (el) => {
    const i = el.i;
    const tempKey = el.tempKey;
    // console.log("key: ", `${i}_${tempKey}`);
    // make all elements NOT draggable for this view
    const gridLayout = { ...el.layout, isDraggable: false };
    return (
      // <div key={i} data-grid={el.layout} style={innerStyle}>
      <div key={`${i}_${tempKey}`} data-grid={gridLayout} style={innerStyle}>
        <GetFormElement
          formBuilder={false}
          formEl={el}
          fieldKey={el.i}
          fieldType={el.fieldType}
          elData={el.formElementData}
          elSettingState={false}
          handleChange={props.handleChange}
          tabIndex={el.layout.y}
          formUser={formUser}
        />
      </div>
    );
  };

  return (
    <ReactGridLayout
      style={containerStyle}
      layout={layout}
      // onLayoutChange={this.onLayoutChange}
      {...props}
    >
      {_.map(formElementsPDF, (el) => createElement(el))}
      {/* {_.map(formElementsData, (el) => createElement(el))} */}
    </ReactGridLayout>
  );
}

NoDraggingLayout.defaultProps = {
  className: "layout",
  isDraggable: false,
  isResizable: false,
  compactType: null,
  cols: 12,
  rowHeight: 30,
};
