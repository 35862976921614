import React, { useEffect, useState } from "react";
import BaseFormElements from "./from-elements-base";
import { useStoreState } from "easy-peasy";
import SettingsBtn from "./form-elements-utils";

const {
  StaticText,
  Title,
  InputCustom,
  SelectCustom,
  TextareaCustom,
  Email,
  Signature,
  ButtonCustom,
  CheckboxCustom,
  RadioButtonCustom,
  RadioButtonCustomReverse,
  DatePicker,
  Divider,
} = BaseFormElements;

const FormElements = {};

FormElements.title = Title;
FormElements.staticText = StaticText;
FormElements.inputField = InputCustom;
FormElements.selectField = SelectCustom;
FormElements.textarea = TextareaCustom;
FormElements.email = Email;
FormElements.signature = Signature;
FormElements.button = ButtonCustom;
FormElements.checkbox = CheckboxCustom;
FormElements.radioButton = RadioButtonCustom;
FormElements.radioButtonReverse = RadioButtonCustomReverse;
FormElements.date = DatePicker;
FormElements.divider = Divider;

const GetFormElement = ({ formBuilder, fieldKey, fieldType, elData, elSettingState, handleChange }) => {
  // const formElementsData = useStoreState((state) => state.formElementsData);
  // const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  // // const currentEl = formElementsPDF.filter((item) => {
  // //   return item.formElementData.isEdit === true;
  // // });
  // const [content, setContent] = useState("Default");
  // const [content, setContent] = useState(elData.content);
  // const currentEl = formElementsPDF.filter((item) => {
  //   return item.formElementData.i === fieldKey;
  // })[0];

  // // TODO remove `currentEl` and remain only `elData`
  // useEffect(() => {
  //   let currentEl = formElementsPDF.filter((item) => {
  //     return item.formElementData.i === fieldKey;
  //   });
  //   if (currentEl && currentEl[0]) {
  //     setContent(currentEl[0].formElementData.content);
  //   }
  // }, [formElementsPDF, fieldKey]);

  return (
    <div>
      {!elSettingState ? "" : <SettingsBtn content={elData.content} fieldKey={fieldKey} />}
      {/* <h3 dangerouslySetInnerHTML={{ __html: content }}></h3> */}
      {FormElements[fieldType] ? (
        FormElements[fieldType]({ formBuilder, elData, handleChange })
      ) : (
        <div>
          <p>
            Element with filedType:<b>{fieldType}</b> does not exist.
          </p>
          {/* <p>Check frontend/src/components/forms/DropForms.js</p> */}
          <p>Check frontend/src/components/forms/FieldsCollection.js</p>
        </div>
      )}
    </div>
  );
};;;

GetFormElement.defaultProps = {
  handleChange: () => void 0,
};

export default GetFormElement;
