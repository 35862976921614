import React, { useState, useEffect } from "react";
import { FormSettingsStyle } from "../../comp/styles/FormSettingsStyle";
import RichTextEditor from "./RichTextEditor";
import { Form } from "react-bootstrap";
import { useStoreState, useStoreActions } from "easy-peasy";
import "./FormSettingPanel.css";

function Switch({ currentEl, label="Required Field", elemParam = 'required' }) {
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const [elemParamValue, setElemParamValue] = useState(null);

  useEffect(() => {
    setElemParamValue(() => {
      if (currentEl) {
        const el = formElementsPDF.filter((item) => {
          return item.isEdit === true;
        })[0];
        if (currentEl.formElementData[elemParam] === undefined) {
          return null;
        } else if (currentEl.formElementData[elemParam]) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    });
  }, [currentEl]);

  const onChange = (e) => {
    updFormElementsPDF(
      formElementsPDF.map((item) => {
        if (item.i === currentEl.i) {
          item.formElementData[elemParam] = e.target.checked;
        }
        return item;
      })
    );
    setElemParamValue(e.target.checked);
  };

  if (elemParamValue === null) {
    return "";
  } else {
    return (
      <Form.Check
        className="ml-4"
        type="switch"
        checked={elemParamValue}
        onChange={onChange}
        id="field-switch"
        label={label}
      />
    );
  }
}

const ListItems = ({ currentEl }) => {
  if (currentEl && currentEl.fieldType === "selectField") {
    const options = currentEl.formElementData.options;
    const listItems = options.map((item, i) => <option key={i.toString()}>{item}</option>);
    return (
      <div>
        <ul>{listItems}</ul>
      </div>
    );
  } else {
    return "";
  }
};

const OptionInput = ({currentEl, label, elemParam, inputType = 'text', isSubItem = false, subItemIndex = 0}) => {
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const [elemParamValue, setElemParamValue] = useState(null);

  useEffect(() => {
    if (!isSubItem) {
      if (currentEl && currentEl.formElementData[elemParam]) {
        setElemParamValue(currentEl.formElementData[elemParam])
      } else {
        setElemParamValue("");
      }
    } else {
      if (currentEl && currentEl.formElementData.subItems[subItemIndex][elemParam]) {
        setElemParamValue(currentEl.formElementData.subItems[subItemIndex][elemParam])
      } else {
        setElemParamValue("");
      }
    }
  }, [currentEl]);

  const applyInputChange = () => {
    updFormElementsPDF(
      formElementsPDF.map((item) => {
        if (item.i === currentEl.i) {
          if (!isSubItem) {
            item.formElementData[elemParam] = elemParamValue;
          } else {
            item.formElementData.subItems[subItemIndex][elemParam] = elemParamValue;
          }
        }
        return item;
      })
    );
  };

  const onInputChange = (e) => {
    setElemParamValue(e.target.value);
  }
  
  if (elemParamValue !== null) {
    return (
      <Form.Group className="mb-2" controlId={`form_input_${elemParam}`}>
        <Form.Label dangerouslySetInnerHTML={{ __html: label }} />
        <Form.Control
          type={inputType}
          name={elemParam}
          onChange={onInputChange}
          value={elemParamValue}
        />
        <button className="btn btn-primary" onClick={applyInputChange}>Apply</button>
      </Form.Group>
    )
  } else {
    return ""
  }
}

const FormSettingPanel = ({ editorDisplay }) => {
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const [currentEl, setCurrentEl] = useState(null);

  useEffect(() => {
    setCurrentEl(() => {
      const el = formElementsPDF.filter((item) => {
        return item.isEdit === true;
      });
      if (el.length) {
        return el[0];
      } else {
        return null;
      }
    });
  }, [formElementsPDF]);

  return (
    <div className={editorDisplay ? "control-sidebar control-sidebar-open shadow" : "control-sidebar "}>
      <div className="sticky">
        <FormSettingsStyle>
          <div>
            <h3>Field Name/Type</h3>
            <p>Text to display:</p>
            <RichTextEditor />
            <ListItems currentEl={currentEl} />

            {/* Required Switch */}
            <Switch currentEl={currentEl} />
            
            {/* Numeric Switch */}
            {currentEl && currentEl.fieldType === 'inputField' ? (
              <Switch currentEl={currentEl} label="Numeric Field" elemParam='isNumeric'/>
            ) : null}

            {/* Min and Max Date */}
            {currentEl && currentEl.fieldType == 'datePicker' ? (
              <React.Fragment>
                <OptionInput currentEl={currentEl} inputType='date' label='Min Date' elemParam='min'/>
                <OptionInput currentEl={currentEl} inputType='date' label='Max Date' elemParam='max'/>
              </React.Fragment>
            ) : null}

             {/* Yes and No for RadioButton */}
             {currentEl && currentEl.formElementData && currentEl.formElementData.subItems && (currentEl.fieldType == 'radioButton' || currentEl.fieldType == 'radioButtonReverse')  ? (
                <React.Fragment>
                  {currentEl.formElementData.subItems.map(function(subItem, i){
                      return (
                        <OptionInput 
                          key={subItem.sub_id}
                          currentEl={currentEl}
                          inputType='text'
                          label={subItem.sub_id == 1 ? 'Radio Button Label 1' : 'Radio Button Label 2'}
                          elemParam='label'
                          isSubItem={true}
                          subItemIndex={i}
                        />
                      )
                  })}
                </React.Fragment>
            ) : null}
           
          </div>
        </FormSettingsStyle>
      </div>
    </div>
  );
};

export default FormSettingPanel;
