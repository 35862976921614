import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PdfResultView from "./ResultView";

const PdfPreviewInModal = () => {
  const [show, setShow] = useState(false);

  const [inputs, setInputs] = useState({});

  // const handleChange = (event) => {
  //   console.log("handleChange");
  //   const name = event.target.name;
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   // const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  // const handleSubmit = (event) => {
  //   console.log("submit");
  //   event.preventDefault();
  //   alert(JSON.stringify(inputs));
  //   // alert(inputs);
  // };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // call even 'resize' to fix width of elements in ReactGridLayout
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 30);
  }, [show]);

  return (
    <>
      <Button className="mt-2" variant="primary" onClick={handleShow}>
        Render PDF
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PdfResultView inputs={inputs} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PdfPreviewInModal;
// For example, `pdfBytes` can be:
//   • Written to a file in Node
//   • Downloaded from the browser
//   • Rendered in an <iframe>
// ===================================================================================
