import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import DraglineOverlay from "./DraglineOverlay_dev";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFView_2() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pdfContainerStyle = {
    // position: "ablsolute",
    position: "relative",
    // width: `${pdfViewerParams.width}px`,
    // height: `${pdfViewerParams.height}px`,
    width: `630px`,
    height: `810px`,
    // boxShadow: "0 0 5px 1px #CCC inset",
    // background: "#F5F8FA",
    // color: "#4A4A4A",
    // margin: 0,
  };
  const pdfElStyle = {
    position: "ablsolute !important",
    // position: "relative",
    // width: `${pdfViewerParams.width}px`,
    // height: `${pdfViewerParams.height}px`,
    width: `600px`,
    height: `700px`,
    // boxShadow: "0 0 5px 1px #CCC inset",
    // background: "#F5F8FA",
    // color: "#4A4A4A",
    // margin: 0,
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file="./adultForm.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        {/* <Page pageNumber={pageNumber} /> */}
        {Array.from(new Array(numPages), (el, index) => (
          <div className="pdf_page_continer" key={`page_${index + 1}`} style={pdfContainerStyle}>
            <Page className="pdf_el" key={`page_${index + 1}`} pageNumber={index + 1} style={pdfElStyle} />
            <DraglineOverlay />
            {/* <hr /> */}
          </div>
        ))}
      </Document>
      <p>Total pages: {numPages}</p>
    </div>
  );
}
