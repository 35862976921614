import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const Congratulations = () => {
  const { state } = useLocation();
  const [profileColor, setProfileColor] = useState(() => {
    let color = localStorage.getItem("profile_bg_color");
    if (color) {
      return color;
    } else {
      return "#1e88e5";
    }
  });
  const [profilelogo, setProfileLogo] = useState(() => {
    let logo = localStorage.getItem("profile_logo");
    if (logo) {
      return logo;
    } else {
      return "";
    }
  });

  return (
    <div className="fill-form-wrapper" style={{ backgroundColor: profileColor }}>
      <Container>
        <Row>
          <Col className="d-flex justify-content-md-center">
            <img src={profilelogo} className="img-fluid mt-1 rounded " style={{ height: "50px" }} />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <h4 className="text-center text-white">
              Thank you for completing your forms! Our team has received them and will review.
            </h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Congratulations;
