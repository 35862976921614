import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const SidebarElement = (props) => {
  const startDragElement = useStoreState((state) => state.startDragElement);
  // const getInitialElementsData = useStoreActions((actions) => actions.getInitialElementsData);
  const updStartDragElement = useStoreActions((actions) => actions.updStartDragElement);
  /**
   * TODO implement with global state
   * this function save data to el "Text/html".
   * Then read this data by onDrop in ../components/draggable/PdfCustom_basic.js
   * @param {event} ev
   */
  const drag = (ev) => {
    console.log("start dragging from sidebar");
    // console.log(ev);
    // console.log(props.fieldType);
    ev.dataTransfer.setData("Text/html", props.fieldType);
    updStartDragElement(props.fieldType);
  };

  return (
    <li
      className="droppable-element"
      draggable={true}
      unselectable="on"
      style={{ cursor: "move" }}
      onDragStart={(e) => drag(e)}
    >
      <p>
        <span>{props.icon}</span>
        {props.text}
      </p>
    </li>
  );
};

export default SidebarElement;
