import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import * as styles from "./styles.module.css";
import { useStoreActions } from "easy-peasy";

const Signature = (props) => {
  const updOnPdfInputValue = useStoreActions((actions) => actions.updOnPdfInputValue);

  const signInput = useRef(null);

  const saveS = () => {
    const dataImgBase64 = signInput.current.toDataURL();
    console.log("Save S");
    console.log(dataImgBase64);

    updOnPdfInputValue({ i: props.elProps.elData.id, inputValue: dataImgBase64 });
    // save to user profile or db
  };
  const clearS = () => {
    console.log("Clear S");
    signInput.current.clear();
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.sigContainer}
        // style={{
        //   border: "1px solid rgb(128,128,128)",
        //   width: "auto",
        //   margin: "auto",
        //   marginTop: 0,
        // }}
      >
        <SignatureCanvas
          ref={signInput}
          penColor="green"
          // backgroundColor="rgba(1,1,1,0.1)"
          canvasProps={{
            className: styles.sigPad,
          }}
        />
      </div>
      <div
        style={{
          border: "1px solid rgb(128,128,128)",
          width: "auto",
          margin: "auto",
        }}
      >
        <Button variant="outline-primary" size="sm" className="m-1" onClick={saveS}>
          Save
        </Button>
        <Button variant="outline-secondary" size="sm" className="m-1" onClick={clearS}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default Signature;
