import React from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopNav from "./TopNav";
import Layout from "./Layout";
import { StoreProvider } from "easy-peasy";
import store from "./Store";
// temp routes
import Home from "./routes/home/Main";
import DragAndDrop from "./routes/drag_and_drop";
import PdfBuild from "./routes/pdf-build";
import PdfBuild_2 from "./routes/pdf-build-2";
import PdfEdit from "./routes/dev/pdf-edit";
import ElementSandbox from "./routes/dev/element_sandbox";
import DragAndDropTutorial from "./routes/dev/drag_and_drop_tutorial";
import TaskList from "./routes/dev/taksList/TaskList";
import FillTheForm from "./routes/fill_form/FillTheForm";
import Congratulations from "./routes/congratulations/Congratulations";
// final routes components
import BuildFromPdf from "./routes/build_from_pdf";
import BuildFromPdf_2 from "./routes/build_from_pdf_2";
import BuildCustom from "./routes/build_custom";
import BuildFromWeb from "./routes/build_from_web";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
// dev components
import LoginAs from "./routes/LoginAs";
import LoginPage from "./routes/LoginPage";
import Header from "./comp/Header";

function App() {
  return (
    <React.Fragment>
      <StoreProvider store={store}>
        <Layout>
          <Router>
            <AuthProvider>
              <TopNav />
              <div>
                {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Routes>
                  {/* prod routes */}
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    element={
                      <PrivateRoute>
                        <LoginAs />
                      </PrivateRoute>
                    }
                    path="login_as"
                    exact
                  />
                  {/* <Route path="home-page" element={<HomePage />} /> */}
                  <Route path="login-page" element={<LoginPage />} />
                  {/* not ready */}
                  <Route path="build-custom" element={<BuildCustom />} />
                  {/* dev routes */}
                  <Route path="dev-" element={<Home />} />
                  <Route path="dev-build-from-pdf" element={<BuildFromPdf />} />
                  <Route path="dev-build-custom" element={<BuildCustom />} />
                  <Route path="dev-build-from-pdf-2" element={<BuildFromPdf_2 />} />
                  {/* <Route path="build-from-web" element={<BuildFromWeb />} /> */}
                  <Route path="dev-element-sandbox" element={<ElementSandbox />} />
                  {/* <Route path="drag-and-drop" element={<DragAndDrop />} /> */}
                  <Route path="dev-pdf-build" element={<PdfBuild />} />
                  <Route path="dev-pdf-build-2" element={<PdfBuild_2 />} />
                  <Route path="dev-pdf-edit" element={<PdfEdit />} />
                  <Route path="dev-drag_and_drop_tutorial" element={<DragAndDropTutorial />} />
                  <Route path="dev-task_list" element={<TaskList />} />
                </Routes>
              </div>
            </AuthProvider>
            <Routes>
              <Route path="fill-form/:uuid" element={<FillTheForm />} />
              <Route path="congratulations" element={<Congratulations />} />
            </Routes>
          </Router>
        </Layout>
      </StoreProvider>
    </React.Fragment>
  );
}

export default App;
