import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SidebarElements from "../components/sidebarToolbox/SidebarElements";
import { FaPlus } from "react-icons/fa";
// import ResponsiveLocalStorageLayout from "../components/draggable/examples/ResponsiveLocalStor";
import DNDLayout from "../components/forms/DNDLayout";
import { Sidebar } from "../comp/sidebarToolbox/styles/Sidebar";
import PdfViewer from "../components/pdf/BaseView";
import DraglineOverlay from "../components/pdf/DraglineOverlay";
import PreviewRenderForm from "../components/forms/PreviewRenderForm";
import FormSettingPanel from "../comp/forms/FormSettingPanel";
import PdfPreviewInModal from "../components/pdf/PreviewInModal";
import { useStoreState } from "easy-peasy";

export default function BuildFromPdf() {
  const [sidebarState, setSidebarState] = useState(true);
  const [sidebarVisibility, setSidebarVisibility] = useState("hide");
  const editorDisplay = useStoreState((state) => state.editorDisplay);
  const hasPdfView = true;

  function toggleSidebar(e) {
    setSidebarState(!sidebarState);
    if (sidebarState) {
      setSidebarVisibility("");
    } else {
      setSidebarVisibility("hide");
    }
  }

  return (
    <Sidebar>
      <main style={{ padding: "0" }}>
        <div className="wrapper">
          <nav id="sidebar" className={sidebarVisibility}>
            <SidebarElements />
          </nav>

          <div id="content" className="w-100">
            <nav className="navbar navbar-expand-lg navbar-light bg-light  p-1">
              <button onClick={toggleSidebar} type="button" className="btn outline-primary">
                <span>
                  Add Elements <FaPlus />
                </span>
              </button>
              <PreviewRenderForm hasPdfView={hasPdfView} />
              {/* testing element */}
              {/* <PdfPreviewInModal /> */}
            </nav>
            <Container fluid className="w-100">
              <Row>
                <Col>
                  <h6>Custom Form </h6>
                  {/* <PdfCustom sidebarState={sidebarState} /> */}
                  <DNDLayout sidebarState={sidebarState} />
                </Col>
                <Col>
                  <div style={{ position: "relative" }}>
                    <h6>Original PDF</h6>
                    <PdfViewer />
                    <DraglineOverlay />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <FormSettingPanel editorDisplay={editorDisplay} />
        </div>
      </main>
    </Sidebar>
  );
}
