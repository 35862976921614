import React from "react";
// import Resize from "../components/draggable/DraglineExample";
import Resize from "../../components/draggable/DraglineExample_2";
import Example from "../../components/draggable/Example";
import PdfResult from "../../components/pdf/ResultView_example";

export default function PdfEdit() {
  return (
    <main style={{ padding: "1rem 0" }}>
      <PdfResult />
      {/* drag line example */}
      <Example />
      <Resize />
    </main>
  );
}
