import React, { useState, useEffect } from "react";
// import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import RenderPDF from './utils/RenderPDF'
import { useStoreState, useStoreActions } from "easy-peasy";

// let formElementsPDF = [
//   {
//     i: "0",
//     fieldType: "title",
//     layout: {
//       i: "0",
//       x: 0,
//       y: 0,
//       w: 12,
//       h: 2,
//       fieldType: "title",
//     },
//     formElementData: {
//       fieldType: "title",
//       key: "Title",
//       name: "Title",
//       static: true,
//       content: "Title...",
//       value: "Value from element",
//     },
//     isEdit: false,
//     elementOnPdf: {
//       id: "0",
//       background: "#8ce8df38",
//       width: 100,
//       height: 50,
//       position: {
//         x: 20,
//         y: 10,
//       },
//     },
//   },
// ];

const PdfResultView = ({ inputs }) => {
  let renderPDF = RenderPDF();
  const [pdfInfo, setPdfInfo] = useState([]);
  // const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const pdfUrl = useStoreState((state) => state.pdfUrl);

  useEffect(() => {
    // modifyPdf();
  }, []);
  useEffect(async () => {
    // modifyPdf();
    const [docUrl, blob, newHeight] = await renderPDF(pdfUrl, setHeight);
    setPdfInfo(docUrl);
    setHeight(newHeight);
  }, [pdfUrl]);

  const iframeStyle = {
    width: "100%",
    // height: "100%",
    height: `${height}px`,
  };

  return <>{<iframe style={iframeStyle} height={height} title="test-frame" src={pdfInfo} type="application/pdf" />}</>;
};;;;

export default PdfResultView;
// For example, `pdfBytes` can be:
//   • Written to a file in Node
//   • Downloaded from the browser
//   • Rendered in an <iframe>
// ===================================================================================
