import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SidebarElements from "../components/sidebarToolbox/SidebarElements";
import { FaPlus } from "react-icons/fa";
import DNDLayout from "../components/forms/DNDLayout";
import PreviewRenderForm from "../components/forms/PreviewRenderForm";
import FormSettingPanel from "../comp/forms/FormSettingPanel";
import { Sidebar } from "../comp/sidebarToolbox/styles/Sidebar";
import { useStoreState } from "easy-peasy";

export default function BuildCustom() {
  const [sidebarState, setSidebarState] = useState(true);
  const [sidebarVisibility, setSidebarVisibility] = useState("hide");
  const editorDisplay = useStoreState((state) => state.editorDisplay);

  function toggleSidebar(e) {
    setSidebarState(!sidebarState);

    if (sidebarState) {
      setSidebarVisibility("");
    } else {
      setSidebarVisibility("hide");
    }
  }

  return (
    <Sidebar>
      <div className="wrapper ">
        <nav id="sidebar" className={sidebarVisibility}>
          <SidebarElements />
        </nav>

        <div id="content" className="w-100">
          <nav className="navbar navbar-expand-lg navbar-light bg-light  p-1">
            <button onClick={toggleSidebar} type="button" className="btn outline-primary">
              <span>
                Add Elements <FaPlus />
              </span>
            </button>
          </nav>
          <Container className="">
            <Row>
              <Col>
                <h6>Custom Form</h6>
                {/* <PdfCustom sidebarState={sidebarState} /> */}
                <DNDLayout sidebarState={sidebarState} />
                <PreviewRenderForm />
              </Col>
            </Row>
          </Container>
        </div>
        <FormSettingPanel editorDisplay={editorDisplay} />
      </div>
    </Sidebar>
  );
}
