import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";

const LoginPage = () => {
  let { loginUser } = useContext(AuthContext);
  // console.log(process.env);
  return (
    <div>
      {/* <div>
        <p>
          You are running this application in <b>{process.env.NODE_ENV}</b> mode.
        </p>
        <p>
          REACT_APP_BACKEND_HOST: <b>{process.env.REACT_APP_BACKEND_HOST}</b>.
        </p>
      </div> */}
      <form onSubmit={loginUser}>
        <input type="text" name="username" placeholder="Enter Username" />
        <input type="password" name="password" placeholder="Enter Password" />
        <input type="submit" />
      </form>
    </div>
  );
};

export default LoginPage;
