import React, { useEffect, useState } from "react";
import { DraggableContainer, DraggableChild } from "react-dragline";
import { useStoreState, useStoreActions } from "easy-peasy";
import ElementDragResize from "./ElementDragResize";

// const initialChildren = [
//   {
//     id: 1,
//     background: "#8ce8df38",
//     width: 100,
//     height: 100,
//     position: { x: 100, y: 0 },
//   },
//   {
//     id: 2,
//     background: "#8ce8df38",
//     width: 100,
//     height: 100,
//     position: { x: 200, y: 106 },
//   },
// ];

function DraglineOverlay() {
  const [flag, setFlag] = useState(false);
  const pdfViewerParams = useStoreState((state) => state.pdfViewerParams);
  // elements on top of PDF
  // const elementsOnPdf = useStoreState((state) => state.elementsOnPdf);
  const formElementsPDF = useStoreState((state) => state.formElementsPDF);
  const [elementsOnPdf, setElementsOnPdf] = useState([]);

  const getInitialElementsOnPdf = useStoreActions(
    (actions) => actions.getInitialElementsOnPdf
  );
  const updElementsOnPdfPsn = useStoreActions(
    (actions) => actions.updElementsOnPdfPsn
  );

  useEffect(() => {
    getInitialElementsOnPdf();
  }, [getInitialElementsOnPdf]);

  useEffect(() => {
    setElementsOnPdf(() => {
      let el = formElementsPDF.map((item) => {
        return item.elementOnPdf;
      });
      return el;
    });
  }, [formElementsPDF]);

  const containerStyle = {
    position: "relative",
    // position: "relative",
    // width: `${pdfViewerParams.width}px`,
    // height: `${pdfViewerParams.height}px`,
    width: `600px`,
    height: `700px`,
    boxShadow: "0 0 5px 1px #CCC inset",
    // background: "#F5F8FA",
    color: "#4A4A4A",
    margin: 0,
  };

  const childStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "move",
  };

  const handleStop = (e, b, id) => {
    // console.log(e, b, id);
    let data = { id: id, x: b.x, y: b.y };
    updElementsOnPdfPsn(data);
  };

  const addFormElements = ({ id, background, position, width, height }) => {
    return (
      <DraggableChild
        key={id}
        defaultPosition={position}
        onStop={(e, b) => handleStop(e, b, id)}
      >
        <div
          className="item"
          style={{
            ...childStyle,
            background,
            // width: width,
            // height: height,
          }}
          onMouseEnter={() => {
            setFlag(true);
          }}
          onMouseLeave={() => {
            setFlag(false);
          }}
        >
          <ElementDragResize
            flag={flag}
            width={width}
            height={height}
            id={id}
          />
        </div>
      </DraggableChild>
    );
  };

  return (
    <div style={{ position: "absolute" }}>
      {/* <style>{".active { opacity: .5; }"}</style> */}
      <DraggableContainer style={containerStyle}>
        {/* {initialChildren.map(({ id, background, position }) => */}
        {elementsOnPdf.map(({ id, background, position, width, height }) =>
          addFormElements({ id, background, position, width, height })
        )}
      </DraggableContainer>
    </div>
  );
}

export default DraglineOverlay;
