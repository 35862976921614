import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import SidebarElements from "../../components/sidebarToolbox/SidebarElements";
import { FaPlus } from "react-icons/fa";
import SignatureDemo from "../../components/forms/SignatureDemo";
import RichTextEditor from "../../comp/forms/RichTextEditor";
import BasicForm from "../../components/forms/demos/BasicForm";

export default function ElementSandbox() {
  const [sidebarState, setSidebarState] = useState(true);
  const [sidebarVisibility, setSidebarVisibility] = useState("hide");

  function toggleSidebar(e) {
    setSidebarState(!sidebarState);

    if (sidebarState) {
      setSidebarVisibility("");
    } else {
      setSidebarVisibility("hide");
    }
  }

  return (
    <Styles>
      <main style={{ padding: "0" }}>
        <div className="wrapper">
          <nav id="sidebar" className={sidebarVisibility}>
            <SidebarElements />
          </nav>

          <div id="content" className="w-100">
            <nav className="navbar navbar-expand-lg navbar-light bg-light  p-1">
              <button onClick={toggleSidebar} type="button" className="btn outline-primary">
                <span>
                  Add Elements <FaPlus />
                </span>
              </button>
            </nav>
            <Container fluid className="w-100">
              <Row>
                <Col>
                  <h6>Elements Sandbox</h6>
                  {/* <BasicLayout sidebarState={sidebarState} /> */}
                  <SignatureDemo />
                  {/* <RichTextEditor />
                  <BasicForm /> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </main>
    </Styles>
  );
}

const Styles = styled.div`
  .wrapper {
    display: flex;
    align-items: stretch;
  }

  #sidebar {
    min-width: 180px;
    max-width: 180px;
    min-height: 80vh;
    background: #58628b;
    color: #fff;
    transition: all 0.3s;
  }

  #sidebar.hide {
    margin-left: -180px;
  }

  #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
  }

  #sidebar ul p {
    color: #fff;
    padding: 10px;
    margin: 0;
  }

  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
  }
  #sidebar ul li a:hover {
    color: #58628b;
    background: #fff;
  }

  #sidebar ul li.active > a,
  a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
  }
  ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
  }
`;
