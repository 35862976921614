import React, { useEffect, useState } from "react";
import { DraggableContainer, DraggableChild } from "react-dragline";
// import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import { Resizable } from "re-resizable";
// import { Resizable } from "react-resizable";
// import ResizeHandle from "./ResizeHandle";

const ResizeHandle = () => {
  return (
    <span className="react-resizable-handle react-resizable-handle-se"></span>
  );
};

const Resize = () => {
  const boxes = [
    { id: 1, position: { x: 100, y: 10 }, width: 200, height: 200 },
    { id: 2, position: { x: 400, y: 200 }, width: 200, height: 200 },
  ];

  const [flag, setFlag] = useState(false);
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);

  useEffect(() => {
    console.log(width);
    console.log(height);
  }, [width, height]);

  const handleResizeStart = (e, direction) => {
    if (direction === "bottomRight") {
      // console.log(direction);
      e.stopPropagation();
    }
  };
  const handleResizeStop = (e, direction, refToElement, delta) => {
    // console.log("width", width);
    // console.log("event", e);
    // console.log("direction", direction);
    // console.log("refToElement", refToElement);
    // console.log("delta", delta);
    setWidth(width + delta.width);
    setHeight(height + delta.height);
    // console.log("width", width);
    // console.log("height", height);
  };

  return (
    <DraggableContainer
      style={{
        height: 900,
        position: "relative",
      }}
      threshold={10}
    >
      {boxes.map(({ id, position, width, height }, index) => {
        const childStyle = {
          display: "flex",
          background: "skyblue",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "move",
        };

        // console.log(height);
        return (
          <DraggableChild key={id} defaultPosition={position}>
            <div
              style={{
                ...childStyle,
                // background,
                // width: size,
                // height: size,
              }}
              onMouseEnter={() => {
                setFlag(true);
              }}
              onMouseLeave={() => {
                setFlag(false);
              }}
            >
              {/* <Resizable height={height} width={width} onResize={onResize}> */}
              <Resizable
                defaultSize={{
                  width: width,
                  height: height,
                }}
                enable={{
                  top: false,
                  right: false,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: true,
                  bottomLeft: false,
                  topLeft: false,
                }}
                onResizeStart={handleResizeStart}
                onResizeStop={handleResizeStop}
                handleComponent={{
                  bottomRight: flag ? <ResizeHandle /> : "",
                }}
              >
                <div
                  className="box"
                  style={{ width: width + "px", height: height + "px" }}
                >
                  <span>Contents</span>
                </div>
              </Resizable>
            </div>
          </DraggableChild>
        );
      })}
    </DraggableContainer>
  );
};

export default Resize;
