// import React, { useState, useEffect } from "react";
// import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

// const PdfExample = () => {
//   const [pdfInfo, setPdfInfo] = useState([]);

//   useEffect(() => {
//     modifyPdf();
//   }, []);

//   const modifyPdf = async () => {
//     const existingPdfBytes = await fetch(
//       "https://pdf-lib.js.org/assets/with_update_sections.pdf"
//     ).then((res) => res.arrayBuffer());

//     const pdfDoc = await PDFDocument.load(existingPdfBytes);
//     const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

//     const pages = pdfDoc.getPages();
//     const firstPage = pages[0];

//     console.log("modifyPdf test");

//     // Get the width and height of the first page
//     const { width, height } = firstPage.getSize();
//     firstPage.drawText("This text was added with JavaScript!", {
//       x: 40,
//       y: height / 2 - 30,
//       size: 20,
//       font: helveticaFont,
//       color: rgb(0.95, 0.1, 0.1),
//       //   rotate: degrees(-45),
//     });

//     const pdfBytes = await pdfDoc.save();
//     // const docUrl = URL.createObjectURL(
//     //   new Blob(pdfBytes, { type: "application/pdf" })
//     // );

//     var bytes = new Uint8Array(pdfBytes);
//     var blob = new Blob([bytes], { type: "application/pdf" });
//     const docUrl = URL.createObjectURL(blob);

//     setPdfInfo(docUrl);
//   };

//   const iframeStyle = {
//     width: "100%",
//     height: "100%",
//   };

//   return (
//     <>
//       {
//         <iframe
//           style={iframeStyle}
//           title="test-frame"
//           src={pdfInfo}
//           type="application/pdf"
//         />
//       }
//     </>
//   );
// };

// export default PdfExample;
// For example, `pdfBytes` can be:
//   • Written to a file in Node
//   • Downloaded from the browser
//   • Rendered in an <iframe>
// ===================================================================================

import React, { useState, useRef } from "react";
import { usePdf } from "@mikecousins/react-pdf";

export default function MyPdfViewer() {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: "test.pdf",
    scale: "0.8",
    page,
    canvasRef,
  });
  console.log(pdfDocument);
  if (pdfDocument) {
    pdfDocument.getPage(1).then((page) => {
      console.log(page);
    });
  }

  return (
    <div>
      {!pdfDocument && <span>Loading...</span>}
      {console.log(pdfDocument)}
      <canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav>
          {/* <ul className="pager">
            <li className="previous">
              <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                Previous
              </button>
            </li>
            <li className="next">
              <button
                disabled={page === pdfDocument.numPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </li>
          </ul> */}
        </nav>
      )}
    </div>
  );
}
